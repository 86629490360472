import React from 'react';
import { getCDNLogoDocument } from "../../../../../js/CDNUtility";
import { getIconUrlToPrint } from '../../../../../js/PrintUtility';

export const PrintVoucherHeader = () => {
    return (
        <>
            <div style={{ padding: "10px" }}>
                <table>
                    <tr style={{ borderBottom: '1px solid #d0d1d6' }}>
                        <td>
                            <img src={getCDNLogoDocument()} className="logo-print" alt="Logo" />
                        </td>
                        <td style={{ fontSize: '16px', maxWidth: "13px" }}>
                            <img src={getIconUrlToPrint('phone')} alt="" className="ico-image" />
                        </td>
                        <td style={{ fontSize: '16px', textAlign: "left" }}>
                            <span>+39 02 24121466</span>
                        </td>
                        <td style={{ fontSize: '16px', maxWidth: "13px" }}>
                            <img src={getIconUrlToPrint('email')} alt="" className="ico-image" />
                        </td>
                        <td style={{ fontSize: '16px', textAlign: "left" }}>
                            <span>assistenza@going.it</span>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
}