import { Template } from "./components/Template/Template";
import { Engine } from "./components/Engine";
import { HotelList } from "./components/Product/Hotel/HotelList";
import { HotelMap } from "./components/Product/Hotel/HotelMap";
import { HotelDetail } from "./components/Product/Hotel/HotelDetail";
import { HotelDetailStatic } from "./components/Product/Hotel/HotelDetailStatic";
import { ActivityList } from "./components/Product/Activity/ActivityList";
import { ActivityDetail } from "./components/Product/Activity/ActivityDetail";
import { ActivityDetailStatic } from "./components/Product/Activity/ActivityDetailStatic";
import { FlightList } from "./components/Product/Flight/FlightList";
import { FlightDetail } from "./components/Product/Flight/FlightDetail";
import { TrainList } from "./components/Product/Train/TrainList";
import { TransferList } from "./components/Product/Transfer/TransferList";
import { CarList } from "./components/Product/Car/CarList";
import { CarDetail } from "./components/Product/Car/CarDetail";
import { TourList } from "./components/Product/Tour/TourList";
import { TourDetail } from "./components/Product/Tour/TourDetail";
import { TourDetailMap } from "./components/Product/Tour/TourDetailMap";
import { TourDetailStatic } from "./components/Product/Tour/TourDetailStatic";
import { CruiseList } from "./components/Product/Cruise/CruiseList";
import { CruiseDetail } from "./components/Product/Cruise/CruiseDetail";
import { Trippie } from "./components/Trippie/Trippie";
import { RegistrationGiftCards } from "./components/MyBook/GiftCards/RegistrationGiftCards";
import { RedirectProduct } from "./components/Product/RedirectProduct";


const AppRoutes_Website = [
    {
        path: '/Engine',
        element: <Engine />,
        visibleOnlyBook: false
    },
    {
        path: '/home',
        element: <Engine />,
        visibleOnlyBook: false
    },
    {
        path: '/redirectproduct',
        element: <RedirectProduct />,
        visibleOnlyBook: false
    },
    {
        path: '/hotelhome',
        element: <Engine startModule="HOTEL"  haveCustomWebContent={true}  />,
        visibleOnlyBook: false
    },
    {
        path: '/Hotel/HotelList/',
        element: <HotelList />,
        visibleOnlyBook: false
    },
    {
        path: '/Hotel/HotelMap/',
        element: <HotelMap />,
        visibleOnlyBook: false
    },
    {
        path: '/Hotel/HotelDetail/',
        element: <HotelDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/Hotel/HotelDetailStatic/',
        element: <HotelDetailStatic />,
        visibleOnlyBook: false
    },
    {
        path: '/activityhome',
        element: <Engine startModule="ACTIVITY" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Activity/ActivityList/',
        element: <ActivityList />,
        visibleOnlyBook: false
    },
    {
        path: '/Activity/ActivityDetail/',
        element: <ActivityDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/Activity/ActivityDetailStatic/',
        element: <ActivityDetailStatic />,
        visibleOnlyBook: false
    },
    {
        path: '/flighthome',
        element: <Engine startModule="FLIGHT" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Flight/FlightList/',
        element: <FlightList />,
        visibleOnlyBook: false
    },
    {
        path: '/Flight/FlightDetail/',
        element: <FlightDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/trainhome',
        element: <Engine startModule="TRAIN" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Train/TrainList/',
        element: <TrainList />,
        visibleOnlyBook: false
    },
    {
        path: '/transferhome',
        element: <Engine startModule="TRANSFER" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Transfer/TransferList/',
        element: <TransferList />,
        visibleOnlyBook: false
    },
    {
        path: '/carhome',
        element: <Engine startModule="CARRENTAL" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/carRental/CarRentalList/',
        element: <CarList />,
        visibleOnlyBook: false
    },
    {
        path: '/carRental/CarRentalDetail/',
        element: <CarDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/tourhome',
        element: <Engine startModule="TOUR" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Tour/TourList/',
        element: <TourList />,
        visibleOnlyBook: false
    },
    {
        path: '/Tour/TourDetail/',
        element: <TourDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/Tour/TourDetailMap/',
        element: <TourDetailMap />,
        visibleOnlyBook: false
    },
    {
        path: '/Tour/TourDetailStatic/',
        element: <TourDetailStatic />,
        visibleOnlyBook: false
    },
    {
        path: '/cruisehome',
        element: <Engine startModule="CRUISE" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Cruise/CruiseList/',
        element: <CruiseList />,
        visibleOnlyBook: false
    },
    {
        path: '/Cruise/CruiseDetail/',
        element: <CruiseDetail />,
        visibleOnlyBook: false
    },
    {
        path: '/template/:paramTemplate',
        element: <Template />,
        visibleOnlyBook: false
    },
    {
        path: '/trippiehome',
        element: <Engine startModule="TRIPPIE" haveCustomWebContent={true} />,
        visibleOnlyBook: false
    },
    {
        path: '/Trippie',
        element: <Trippie />,
        visibleOnlyBook: false
    }, {
        path: '/giftcards',
        element: <RegistrationGiftCards />,
        visibleOnlyBook: false
    }
];

export default AppRoutes_Website;
