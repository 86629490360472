import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom, replaceAllStyleElements  } from '../../../js/PrintUtility';

export const QuotationPrintProductsNotes = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            <h2 style={{ marginLeft: '0px', marginRight: '20px' }}>{props.name}</h2>
            <span style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: props.note }} />
        </>
    );
}