import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n';
import Cookies from 'universal-cookie';

export const HeaderChangeLanguage = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();

    const [language, setLanguage] = useState("");

    useEffect(() => {
        if (!language && !cookies.get("CultureName")) {
            setCookiesAndLanguage("en-US");
        }
        else if (!language || (language !== cookies.get("CultureName"))) {
            setCookiesAndLanguage(cookies.get("CultureName"));
        }
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
       
        if (lng === "it") {
            setCookiesAndLanguage("it-IT");
            changeSessionLanguage("it-IT");
        }
        else {
            setCookiesAndLanguage("en-US");
            changeSessionLanguage("en-US");
        }
    }

    function setCookiesAndLanguage(language) {
        cookies.remove("CultureName", { path: '/' });

        const expireDate = new Date();
        expireDate.setHours(23, 59, 59, 999);

        cookies.set("CultureName", language, { path: '/', expires: expireDate });

        setLanguage(language);
    }

    async function changeSessionLanguage(language) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };
        
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}auth/changeSessionLanguage/${language}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                window.location.reload();
            }
        }
    }

    return (
        <>
            <div className="d-none d-sm-flex">
                <div className={"d-inline-block mr-3 cursor-pointer " + (language === "it-IT" ? "" : "opacity-50")} onClick={() => changeLanguage('it')}>
                    <img src="https://cdn.travelplace.ch/common/images/ico/language/it-it.png" width="35px" alt="" />
                </div>
                <div className={"d-inline-block mr-3 cursor-pointer " + (language === "en-US" ? "" : "opacity-50")} onClick={() => changeLanguage('en')}>
                    <img src="https://cdn.travelplace.ch/common/images/ico/language/en-en.png" width="35px" alt="" />
                </div>
            </div>
        </>
    )
}
