import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const FlightSummary = ({ flight, isAlternative }) => {
    const { t } = useTranslation();
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [flight]);


    return (
        <>
            <span m3ico="Flight mr-2 icon-15">
                <M3Icon iconName="Flight" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
            </span>

            {flight.multiItineraryName && flight.multiItineraryName}
            {!flight.multiItineraryName && <>{t(`Product:Flight:Item`)} {flight.itineraryName}</>}
        </>
    );
}
