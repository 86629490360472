import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { PassengerPrint } from './PassengerPrint';
import { PriceBarPrint } from './PriceBarPrint';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { imageOnError } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements } from '../../../js/PrintUtility';
import { PassengerDetailPrint } from './PassengerDetailPrint';

export const ActivityDetailPrint = ({ id, type, showNetPrices, activityDetail, isPrintCliente, productSubType, isNotesHide, isConfirmPrint = false }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function getTitleFromSubType(productSubType) {
        let res;

        if (productSubType === 51)
            res = "VOLI";
        else if (productSubType === 12)
            res = "TOUR";
        else if (productSubType === 41)
            res = "TRASFERIMENTI";
        else if (productSubType === 21)
            res = "NOLEGGIO AUTO";
        else
            res = "VISITE & ESCURSIONI";

        return res;
    }


    return (
        <>
            {activityDetail ?
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0 text-white">{getTitleFromSubType(activityDetail.productSubType)}</h6>
                    </div>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                                <td className="borderThead" style={{ width: '18%', maxWidth: '18%' }}>
                                    <h6>Immagine</h6>
                                </td>
                                <td className="borderThead" style={{ width: '40%', maxWidth: '40%' }}>
                                    <h6>Descrizione Servizio</h6>
                                </td>
                                <td className="borderThead">
                                    <h6>Località Partenza</h6>
                                </td>

                                {productSubType != 'Tour' ?
                                    <td className="borderThead">
                                        <h6>Data</h6>
                                    </td>
                                    :
                                    <>
                                        <td className="borderThead">
                                            <h6>Località Arrivo</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Tipo Camera</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Data</h6>
                                            <h6>Inizio</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Data</h6>
                                            <h6>Fine</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Durata</h6>
                                            <h6>Giorni</h6>
                                        </td>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                <td className="borderTBody" style={{ width: '18%', maxWidth: '18%' }}>
                                    <img src={`data:image;base64, ` + activityDetail.thumbUrlB} alt="Thumb" className="card-img" onError={imageOnError} />
                                </td>
                                <td className="borderTBody" style={{ width: '40%', maxWidth: '40%' }}>
                                    <h6>{activityDetail.name}</h6>
                                </td>
                                <td className="borderTBody">
                                    <h6>{activityDetail.address}</h6>
                                </td>
                                {productSubType != 'Tour' ?
                                    <td className="borderTBody">
                                        <h6>{formatDateTime(activityDetail.priceBar.startDate, cultureName)}</h6>
                                    </td>
                                    :
                                    <>
                                        <td className="borderTBody">
                                            <h6>{activityDetail.DestinationName ? activityDetail.DestinationName : activityDetail.address}</h6>
                                        </td>
                                        <td className="borderTBody">
                                            <h6>{activityDetail.option.optionName}</h6>
                                        </td>
                                        <td className="borderTBody">
                                            <h6>{formatDateTime(activityDetail.priceBar.startDate, cultureName)}</h6>
                                        </td>
                                        <td className="borderTBody">
                                            <h6>{formatDateTime(activityDetail.priceBar.endDate, cultureName)}</h6>
                                        </td>
                                        <td className="borderTBody">
                                            <h6>{activityDetail.option.optionDuration}</h6>
                                        </td>
                                    </>
                                }
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        {/*note*/}
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: (isPrintCliente ? '1px dotted grey' : 'none'), padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Note aggiuntive</h5>
                                {isNotesHide ?
                                    <span>Vedi in fondo alla pagina</span>
                                    :
                                    activityDetail.quotationDetail != null && activityDetail.option.notes && activityDetail.option.notes.length > 0 ?
                                        <div> {/* da lascaire per problema split pagina react PDF */}
                                            {activityDetail.option.notes && activityDetail.option.notes.map((note, index) =>
                                                <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} /></p>
                                            )}
                                        </div>
                                        : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*totale costo*/}
                        {!isPrintCliente &&
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {isConfirmPrint &&
                                        <h5>{t(`statiPrenotazione:` + activityDetail.option.priceBar.status)}</h5>
                                    }
                                    <label>{activityDetail.option.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(activityDetail.option.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(activityDetail.option.priceBar.invoicePrice, activityDetail.option.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        }
                    </table>
                    <table>
                        {/*passeggeri*/}
                        {
                            <PassengerDetailPrint
                                passengers={activityDetail.option.passengers}
                            />
                        }
                    </table>
                </div>
                :
                <></>
            }
        </>
    );
}