import React from 'react';
import Cookies from 'universal-cookie';
import { formatPrice } from '../../../js/Utils.js';


export const ConfirmPrintCommissionsDetail = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function getNettoPagare(marginItems) {
        let total = 0;
        let currency = 0;
        marginItems.forEach(function (item) {
            if (item.isCommissionable) {
                currency = item.currency;
                total = total + (item.invoicePrice - (item.commissioneAgenzia + item.agencyFee));
            }
            else {
                currency = item.currency;
                total = total + item.invoicePrice;
            }
        });

        return formatPrice(total, currency, props.cultureName);
    }

    function getIsCommissionable() {
        let isCommissionable = false;
        for (var i = 0; i < props.marginItems.length; i++) {
            if (props.marginItems[i].isCommissionable) {
                isCommissionable = true;
                break;
            }
        }

        return isCommissionable;
    }

    return (
        <>
            {props.marginItems && !props.isPrintCliente ?
                <div style={{ display: 'grid' }}>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }}>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h4>{props.totaleViaggio} </h4>
                                </td>
                                <td className="borderThead">
                                    <h4>{getIsCommissionable() ? "Commissionabile" : "Netto" }</h4>
                                </td>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h4>{getNettoPagare(props.marginItems)}</h4>
                                </td>
                            </tr>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }}>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h6>Totale Viaggio</h6>
                                </td>
                                <td className="borderThead">
                                    <h6>Dettaglio Commissioni</h6>
                                </td>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h6>Netto da Pagare</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.marginItems && props.marginItems.length > 0 &&
                                props.marginItems.map((item, index) =>
                                    <>
                                        {!item.isCommissionable &&
                                            <tr key={index} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                                <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'left' }}>
                                                    <h6>{formatPrice(item.invoicePrice, item.currency, props.cultureName)}</h6>
                                                </td>
                                                <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                    <h6>{item.productName}</h6>
                                                </td>
                                                <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'right' }}>
                                                    <h6>{formatPrice(item.invoicePrice, item.currency, props.cultureName)}</h6>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            }
                            {props.marginItems && props.marginItems.length > 0 &&
                                props.marginItems.map((item, index) =>
                                    <>
                                        {item.isCommissionable &&
                                            <tr key={index} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }}>
                                                <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'left' }}>
                                                    <h6>{formatPrice(item.invoicePrice, item.currency, props.cultureName)}</h6>
                                                </td>
                                                <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                    <h6>{item.productName} ({formatPrice((item.commissioneAgenzia + item.agencyFee), item.currency, props.cultureName)})</h6>
                                                </td>
                                                <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'right' }}>
                                                    <h6>
                                                        {formatPrice((item.invoicePrice - (item.commissioneAgenzia + item.agencyFee)), item.currency, props.cultureName)}
                                                        {
                                                            parseFloat(item.totalIVACee) > 0 ?
                                                                <>
                                                                    (* {item.totalIVACee})
                                                                </>
                                                                : ""
                                                        }
                                                    </h6>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            : <></>
            }
        </>
    );
}