import React from 'react';
import Cookies from 'universal-cookie';

export const QuotationPrintImageDynamicNotes = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {props.notaImg != null?
                <>
                    <div style={{paddingBottom: '20px'}}>
                        <img src={`data:image;base64, ` + props.notaImg} alt="Thumb" className="img-note" />
                    </div>
                </>
                :
                <></>
            }
        </>
    );
}