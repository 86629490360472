import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';

export const ConfirmPrintTitle = (props) => {
    return (
        <>
            {props.orderNumber ?
                <table style={{ marginTop: '-30px', marginBottom: '20px' }}>
                    <tr>
                        <td>
                        </td>
                        <td style={{ textAlign: 'left', borderLeft: '1px dotted grey', borderBottom: '1px dotted grey' }}>
                            <h5>N. Conferma: </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderRight: '1px dotted grey', borderBottom: '1px dotted grey' }}>
                            <h5>{props.orderNumber && props.orderNumber} </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderBottom: '1px dotted grey' }}>
                            <h5>Data: </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderBottom: '1px dotted grey', borderRight: '1px dotted grey' }}>
                            <h5>{props.date && formatDateTime(props.date, props.cultureName)} </h5>
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
            : <></>
            }
        </>
    );
}