import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { imageOnError, imageOnLoad } from '../../../js/CDNUtility';
import { replaceAllStyleElements, getIconUrlToPrint } from '../../../js/PrintUtility';
import { PassengerDetailPrint } from './PassengerDetailPrint';


export const CruiseDetailPrint = ({ id, type, showNetPrices, cruiseDetail, isPrintCliente, isNotesHide, isConfirmPrint = false }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {cruiseDetail ?
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0 text-white">CROCIERE</h6>
                    </div>
                    <table>
                        <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                            <td className="borderThead" style={{ width: '18%', maxWidth: '18%' }}>
                                <h6>Immagine</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Nome</h6>
                                <h6>Nave</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Porto</h6>
                                <h6>Imbarco</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Porto</h6>
                                <h6>Arrivo</h6>
                            </td>
                            <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                <h6>Tipo</h6>
                                <h6>Cabina</h6>
                            </td>
                            <td className="borderThead" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>Data</h6>
                                <h6>Imbarco</h6>
                            </td>
                            <td className="borderThead" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>Data</h6>
                                <h6>Arrivo</h6>
                            </td>
                            <td className="borderThead" style={{ width: '7%', maxWidth: '7%', textAlign: 'center' }}>
                                <h6>Durata</h6>
                                <h6>Giorni</h6>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }}>
                            <td className="borderTBody" style={{ width: '18%', maxWidth: '18%' }}>
                                <img src={`data:image;base64, ` + cruiseDetail.shipImageUrlB} alt="Thumb" className="card-img" onError={imageOnError} />
                            </td>
                            <td className="borderTBody">
                                <h6>{cruiseDetail.shipName}</h6>
                            </td>
                            <td className="borderTBody">
                                <h6>{cruiseDetail.itinerarySegments[0].portName}</h6>
                            </td>
                            <td className="borderTBody">
                                <h6>{cruiseDetail.itinerarySegments[cruiseDetail.itinerarySegments.length - 1].portName}</h6>
                            </td>
                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%' }}>
                                <h6>{cruiseDetail.cabinCategory.name}</h6>
                            </td>
                            <td className="borderTBody" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>{formatDateTime(cruiseDetail.priceBar.startDate, cultureName)}</h6>
                                <h6>{cruiseDetail.itinerarySegments[0].departure}</h6>
                            </td>
                            <td className="borderTBody" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>{formatDateTime(cruiseDetail.priceBar.endDate, cultureName)}</h6>
                                <h6>{cruiseDetail.itinerarySegments[cruiseDetail.itinerarySegments.length - 1].arrival}</h6>
                            </td>
                            <td className="borderTBody" style={{ width: '7%', maxWidth: '7%', textAlign: 'center' }}>
                                <h6>{cruiseDetail.duration}</h6>
                            </td>
                        </tr>
                    </table>
                    {cruiseDetail.cabinCategory && cruiseDetail.cabinCategory.includeServices && cruiseDetail.cabinCategory.includeServices.length > 0 && <table>
                        {
                            <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: '1px solid grey', padding: '5px' }}>
                                <td style={{ textAlign: 'left' }} >
                                    <h5>Servizi Inclusi</h5>
                                    <tr style={{ textAlign: 'left' }}>
                                        <td>
                                            {
                                                cruiseDetail.cabinCategory.includeServices.map((extra, key) => {
                                                    return <React.Fragment key={key}>
                                                        <label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(extra.name + " (" + extra.itemCode) + ")" }} />
                                                        {
                                                            key < (cruiseDetail.cabinCategory.includeServices.length - 1) ? <>, </> : <></>
                                                        }
                                                    </React.Fragment>
                                                })
                                            }
                                        </td>
                                    </tr>
                                </td>
                            </tr>
                        }
                    </table>
                    }
                    <table>
                        {/*itinerario*/}
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: '1px solid grey', padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Itinerario</h5>
                                {cruiseDetail.itinerarySegments.length > 1 ?
                                    <>
                                        <tr style={{ textAlign: 'left' }}>
                                            <td>
                                                <h5>Giorno</h5>
                                            </td>
                                            <td>
                                                <h5>Porto</h5>
                                            </td>
                                            <td>
                                                <h5>Partenza</h5>
                                            </td>
                                            <td>
                                                <h5>Arrivo</h5>
                                            </td>
                                        </tr>
                                        {cruiseDetail.itinerarySegments.map((segment, index) =>
                                            <tr key={index} style={{ marginTop: '-9px' }}>
                                                <td>
                                                    <h6>{formatDateTime(segment.day, cultureName)}</h6>
                                                </td>
                                                <td>
                                                    <h6>{segment.portName}</h6>
                                                </td>
                                                <td>
                                                    <h6>{segment.departure}</h6>
                                                </td>
                                                <td>
                                                    <h6>{segment.arrival}</h6>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                    : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*note*/}
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: (isPrintCliente ? '1px dotted grey' : 'none'), padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Note aggiuntive</h5>
                                {isNotesHide ?
                                    <span>Vedi in fondo alla pagina</span>
                                    :
                                    cruiseDetail.note ?
                                        <div> {/* da lascaire per problema split pagina react PDF */}
                                            <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: cruiseDetail.note }} /></p>
                                        </div>
                                        : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*totale costo*/}
                        {!isPrintCliente &&
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {isConfirmPrint &&
                                        <h5>{t(`statiPrenotazione:` + cruiseDetail.priceBar.status)}</h5>
                                    }
                                    <label>{cruiseDetail.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(cruiseDetail.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(cruiseDetail.priceBar.invoicePrice, cruiseDetail.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        }
                    </table>
                    <table>
                        {/*passeggeri*/}
                        {
                            <PassengerDetailPrint
                                passengers={cruiseDetail.cabinCategory.passengers}
                            />
                        }
                    </table>
                </div >
                :
                <></>
            }
        </>
    );
}