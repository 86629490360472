import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken, isMobile } from '../../../js/Utils.js';
import { M3Icon } from "../../Common/M3Icon";
import { ProductHeader } from '../Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { ProductItemNotFound } from '../Common/ProductItemNotFound';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { getTourDetailLink } from '../../../js/ProductService.js';
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { TourItemList } from './components/TourItemList';
import { TourItemListBlock } from './components/TourItemListBlock';
import { TourModalStatic } from './components/TourModalStatic';
import { ListSearchEngineCVMobile } from '../SearchEngine/compactView/ListSearchEngineCVMobile';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const TourList = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');

    let extraParam = {
        carpetId: carpetId,
        clientFlowId: clientFlowId,
        productId: 0
    }

    // carpet
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [itemsListMode, setItemsListMode] = useState(0);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);

    const [modalOpenProductId, setModalOpenProductId] = useState(null);
    const [searchEngineExtraParams, setSearchEngineExtraParams] = useState(null);
    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);


    const [touchPoints, setTouchPoints] = useState([]);
    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    const [filterSelected, setFilterSelected] = useState({
        rangePrice: null,
        tourName: '',
        touchPoints: [],
        departurePoints: [],
        duration: [],
        tourLanguages: [],
        suppliers: [],
        subSuppliers: [],
        amenities: [],
        pageNumber: 0,
        orderBy: searchCarpetParameter.tour.orderBy
    });


    // paging
    const itemsPerPage = searchCarpetParameter.tour.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    useEffect(() => {
        if (isMobile())
            setItemsListMode(1);

        setIsLoading(true);
        setIsError(false);
        getCarpetStatus();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);


                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);
            }

        } catch (ex) {
        }

    }, []);


    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.tour.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    // API carpet
    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getTourListFilters();

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.tour.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.tour.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.tour.carpetPingMs);
            addInterval(interval);
        } else {
            setIsLoadingFilter(false);
        }
    }

    async function getTourList(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: pageNumber,
            pageSize: searchCarpetParameter.tour.listMaxItem,
            showCost: showCost,
            tourName: filterSelectedUpdate.tourName,
            touchPoints: filterSelectedUpdate.touchPoints,
            departurePoints: filterSelectedUpdate.departurePoints,
            tourDuration: filterSelectedUpdate.duration,
            tourLanguages: filterSelectedUpdate.tourLanguages,
            suppliers: filterSelectedUpdate.suppliers,
            subSuppliers: filterSelectedUpdate.subSuppliers,
            rangePrice: filterSelectedUpdate.rangePrice,
            orderBy: filterSelectedUpdate.orderBy,
            amenities: filterSelectedUpdate.amenities
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/gettourlist`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);
            renderPaginator(response.totalTours);

            let tmpTouchPoints = [];
            response.tours.forEach(function (tour) {
                tour.touchPoints.forEach(function (touchPoint) {
                    if (tmpTouchPoints.length === 0 || !tmpTouchPoints.some(x => x.id === touchPoint.id))
                        tmpTouchPoints.push(touchPoint);
                });
            });
            setTouchPoints(tmpTouchPoints);
    
            if (tmpTouchPoints && tmpTouchPoints.length > 0) {
                setSearchEngineExtraParams({ touchPoints: tmpTouchPoints });
            }

        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }
        setIsLoadingListResult(false);
    }

    async function getTourListFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/gettourlistfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });
            }

            getTourList(filterSelected, 0);
        }
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);
        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }
    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }
    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        setIsLoadingFilter(true);
        getTourList(filterSelectedUpdate, 0);
    }

    const onChangeAZ = (e) => {
        setIsOrderAZ(!isOrderAZ);

        if (filterSelected.orderBy === "3") { // nome az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "2"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "2") { // nome za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "3"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "4") { // distanza az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "8"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "8") { // distanza za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "4"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'TourDuration': {
                filterSelectedUpdate.duration = selectedItems;
                break;
            }
            case 'TouchPoints': {
                filterSelectedUpdate.touchPoints = selectedItems;
                break;
            }

            case 'Suppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }

            case 'SubSuppliers': {
                filterSelectedUpdate.subSuppliers = selectedItems;
                break;
            }

            case 'Amenities': {
                filterSelectedUpdate.amenities = selectedItems;
                break;
            }
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(name) {
        let filterSelectedUpdate = {
            ...filterSelected,
            tourName: name
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            categories: [],
            mealplans: [],
            amenities: [],
            suppliers: [],
            subSuppliers: [],
            rangePrice: null
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = {
            ...filterSelected,
            pageNumber: e.selected + 1
        }

        setFilterSelected(filterSelectedUpdate);
        getTourList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    function onChangeItemsListMode(mode) {
        setItemsListMode(mode);
    }

    function getFilterOrderByValue(orderValue) {
        let res = null;

        searchCarpetParameter.tour.orderByValues.forEach(function (order) {
            if (order.descendingValue === parseInt(orderValue)) {
                res = order.value;
            }
        });

        if (res == null) {
            res = orderValue;
        }

        return res;
    }

    async function onClickSearch(tourId, supplier, filters) {
        let extraParam = '';

        let url = await getTourDetailLink(filterValues.tourSearchParameters, tourId, carpetId, clientFlowId, false, null, supplier);
        if (extraParam)
            url += extraParam;


        if (url)
            window.open(url, '_blank');
    }


    return (
        <>
            <div className="bg-bluesoft-100">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="TOUR" />
                }
                {
                    configData.Settings.Website.ShowProductHeaderList && <div>
                        <ProductHeader text="" pageCode='Home_Gallery' />
                    </div>
                }
                <div className="container ">
                    <div className="row mt-4">
                        {/* Loading */}
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:Tour:LoadingList')} /></div>}

                        {/* Error */}
                        {!isLoading && isError && (!items || !items.tours || items.tours.length === 0) && <div className="col-12"><Error textMsg={t('Product:Tour:Error')} /></div>}

                        {/* Items */}
                        {!isLoading && items &&
                            <>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    {
                                        filterValues && <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-2 p-2" onClick={(e) => onClearFilter(e)}>
                                            <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                        </button>
                                    }
                                </div>

                                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                <data m3lab="Product:Tour.NTour">
                                                    {t(`Product:Tour:NTour`)}:
                                                </data>
                                                <span className="ml-2">
                                                    <var>
                                                        {items.totalTours}
                                                    </var>
                                                    {
                                                        isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 pt-1 d-none d-sm-block text-right">
                                    <div className="row">
                                        <div className="col-7 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    { /* messo 4 solo perchè di default le activity hanno ordinamento per rilevanza */}
                                                    <option value="4">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.activity.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                    <data m3ico="SortBy icon-15 text-bluesoft">
                                                        <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                    </data>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25">
                                                    <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25">
                                                    <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-1 text-right d-block d-sm-none">
                                    <div className="d-flex mb-4 align-items-center" style={{ gap: "1rem" }}>
                                        <div className="col-7 text-right pr-0 pl-0">
                                            <span className="input-group text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.flight.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>

                                            </span>
                                        </div>
                                        <div className="">
                                            <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25">
                                                    <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25">
                                                    <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                        <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                            {t(`General:OpenFilter`)}
                                        </button>
                                    </div>
                                </div>
                                {filterValues && <div className="d-block d-sm-none">
                                    <ListSearchEngineCVMobile
                                        imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                        searchParameters={filterValues.tourSearchParameters}
                                        productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                        typeSearch="SRC"
                                        extraParams={searchEngineExtraParams}
                                        isShowMap={true}
                                    />
                                </div>}

                                {/* Colonna Filtri */}
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                                    {filterValues && <>
                                        <ListSearchEngineCV
                                            imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                            searchParameters={filterValues.tourSearchParameters}
                                            productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                            typeSearch="SRC"
                                            extraParams={searchEngineExtraParams}
                                            isShowMap={true}
                                        />
                                        <div className='d-none d-sm-block'>
                                            { /* Nome */}
                                            <ProductFilterByName
                                                items={filterValues.tourNames}
                                                label={t("SearchEngine:TourName")}
                                                type="TourName"
                                                onApplyFilter={onApplyFilterName}
                                                resetSelectedItem={isResetSelectedItem} />


                                            { /* Duration */}
                                            <ProductFilter
                                                items={filterValues.duration}
                                                label="Product:Activity:Filter:Duration"
                                                type="TourDuration"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* touch Point */}
                                            <ProductFilter
                                                items={filterValues.touchPoints}
                                                label="Product:Tour:Filter:TouchPoint"
                                                type="TouchPoints"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />


                                            { /* DMC Filter */}
                                            {
                                                filterValues.dmcFilter && filterValues.dmcFilter.map((dmcFilter, key) => {
                                                    return <ProductFilter key={key}
                                                        items={dmcFilter.value}
                                                        label={dmcFilter.type.name}
                                                        type={"DMCFilter_" + dmcFilter.type.id}
                                                        onApplyFilter={onApplyFilter} />
                                                })
                                            }

                                            { /* Amenities */}
                                            <ProductFilter
                                                items={filterValues.amenities}
                                                label="Product:Tour:Services"
                                                type="Amenities"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Supplier */}
                                            <ProductFilter
                                                items={filterValues.suppliers}
                                                label="Product:Activity:Filter:Provider"
                                                type="Suppliers"
                                                onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* SubSuppliers */}
                                        <ProductFilter
                                            items={filterValues.subSuppliers}
                                            label="Product:Activity:Filter:SubProvider"
                                            type="SubSuppliers"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />
                                        </div>
                                    </>
                                    }
                                </div>

                                { /* Colonna Items */}
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                    {
                                        !isLoadingListResult && items && items.tours && items.tours.length > 0 && itemsListMode === 0 && items.tours.map((item, key) => {
                                            return <TourItemList key={key}
                                                item={item}
                                                showPriceBar={showPriceBar}
                                                showCost={showCost}
                                                onClickSearch={onClickSearch}
                                                modalOpenProductId={setModalOpenProductId} />
                                        })
                                    }
                                    {
                                        !isLoadingListResult && items && items.tours && items.tours.length > 0 && itemsListMode === 1 && <div className="row">
                                            {
                                                items.tours.map((item, key) => {
                                                    return <TourItemListBlock
                                                        key={key}
                                                        item={item}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        onClickSearch={onClickSearch} />
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        isLoadingListResult && <div className="text-center"><Loading textMsg={t('Product:Tour:LoadingList')} /></div>
                                    }
                                    {
                                        !isLoadingListResult && (!items || (!items.tours || items.tours.length === 0)) && <div className="text-center"><ProductItemNotFound label="Nessun Tour trovato" /></div>
                                    }
                                </div>

                                {
                                    modalOpenProductId && <TourModalStatic tourId={modalOpenProductId} modalCloseProductId={setModalOpenProductId} />
                                }

                                { /* Paginator */}
                                {
                                    items && items.tours && items.tours.length > 0 && <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">

                            {filterValues && <>
                                <ListSearchEngineCV
                                    imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                    searchParameters={filterValues.tourSearchParameters}
                                    productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                    typeSearch="SRC"
                                    extraParams={searchEngineExtraParams}
                                    isShowMap={true}

                                />

                                { /* Nome */}
                                <ProductFilterByName
                                    items={filterValues.tourNames}
                                    label={t("SearchEngine:TourName")}
                                    type="TourName"
                                    onApplyFilter={onApplyFilterName}
                                    resetSelectedItem={isResetSelectedItem} />


                                { /* Duration */}
                                <ProductFilter
                                    items={filterValues.duration}
                                    label="Product:Activity:Filter:Duration"
                                    type="TourDuration"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* touch Point */}
                                <ProductFilter
                                    items={filterValues.touchPoints}
                                    label="Product:Tour:Filter:TouchPoint"
                                    type="TouchPoints"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />


                                { /* DMC Filter */}
                                {
                                    filterValues.dmcFilter && filterValues.dmcFilter.map((dmcFilter, key) => {
                                        return <ProductFilter key={key}
                                            items={dmcFilter.value}
                                            label={dmcFilter.type.name}
                                            type={"DMCFilter_" + dmcFilter.type.id}
                                            onApplyFilter={onApplyFilter} />
                                    })
                                }

                                { /* Amenities */}
                                <ProductFilter
                                    items={filterValues.amenities}
                                    label="Product:Tour:Services"
                                    type="Amenities"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Supplier */}
                                <ProductFilter
                                    items={filterValues.suppliers}
                                    label="Product:Activity:Filter:Provider"
                                    type="Suppliers"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* SubSuppliers */}
                                <ProductFilter
                                    items={filterValues.subSuppliers}
                                    label="Product:Activity:Filter:SubProvider"
                                    type="SubSuppliers"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />
                            </>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}