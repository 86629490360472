import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility';

export const QuotationPrintAncillaries = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // verifica se esite qualche valore
    function checkIfExistValues(recapItems) {
        let ok = recapItems.filter((item) => item.productType === "Generic" && item.isSelected && item.step === '-1')
        if (ok.length > 0)
            return true;
        else
            return false;
    }

    return (
        <>
            {props.recapItems && checkIfExistValues(props.recapItems) ?
                <div>
                    <table>
                        <thead>
                            <tr className="badge-customer">
                                <td className="text-white" style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
                                    <h6>QUOTE ACCESSORIE</h6>
                                </td>
                            </tr>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px', borderRight: '1px', }} >
                                <td className="borderThead">
                                    <h6>Descrizione</h6>
                                </td>
                                {!props.isPrintCliente &&
                                    <td className="borderThead" style={{ width: '30%', maxWidth: '30%', }}>
                                        <h6>Quota in €</h6>
                                    </td>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.recapItems &&
                                props.recapItems.filter((item) => item.productType === "Generic" && item.isSelected && item.step === '-1')
                                    .map((item, key) => 
                                    <>
                                        <tr key={key} style={{ borderBottom: '1px', borderRight: '1px dotted grey' }} >
                                            <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                <h6>{item.productName}</h6>
                                            </td>
                                            {!props.isPrintCliente &&
                                                <td className="borderTBody" style={{ width: '30%', maxWidth: '30%', textAlign: 'right' }}>
                                                    <h6>{formatPrice(item.sellAmount, item.sellCurrency, cultureName)}</h6>
                                                </td>
                                            }
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            : <></>
            }
        </>
    );
}