import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { getIconUrlToPrintCustom, replaceAllStyleElements  } from '../../../js/PrintUtility';

export const ConfirmPrintDynamicNotes = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    
    return (
        <>
            {props.notes != null && props.notes.length > 0 &&
                <>
                    {props.notes.map((note, index) =>
                        <>
                            {props.isShowImage && note.urlBgImageB &&
                                <div style={{ paddingBottom: '10px', marginTop: "10px" }}>
                                    <img src={`data:image;base64, ` + note.urlBgImageB} alt="Thumb" className="img-note" />
                                </div>
                            }
                            {!props.hideText &&
                                <div key={index} style={{ marginBottom: "-10px" }}>
                                    <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note.note) }} />
                                </div>
                            }
                        </>
                    )}
                </>
            }
        </>
    );
}