import React, { useEffect, useState } from 'react';
import configData from "../../../appsettings.json";
import { handleError, formatDateTime, formatPrice, formatNumber } from '../../../js/Utils.js';
import { translateQuotationDiscountStatus } from '../enums/quotationTOEnums';
import { M3Icon } from '../../Common/M3Icon';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { AddServiceNote } from '../../ManualProduct/components/AddServiceNote';

export const QuotationRecapItem = (props) => {
    const { t } = useTranslation();
    const [quotationItemsMarkup, setQuotationItemsMarkup] = useState([]);
    const [quotationItemsCommissionable, setQuotationItemsCommissionable] = useState([]);
    const [quotationItemsIsCommissionable, setQuotationItemsIsCommissionable] = useState([]);
    const [quotationItemsBuyPrice, setQuotationItemsBuyPrice] = useState([]);
    const [quotationAPIsSelected, setQuotationAPIsSelected] = useState([]);
    const [quotationDiscountType, setQuotationDiscountType] = useState([]);

    const [quotationAddictionalProductsToAdd, setQuotationAddictionalProductsToAdd] = useState([]);

    const [addQuotationDiscount, setAddQuotationDiscount] = useState({
        amount: undefined,
        currency: 'EUR',
        idProdotto: 'NOT',
        descrizione: ''
    });
    const [addQuotationDiscountIsValid, setAddQuotationDiscountIsValid] = useState({
        discountType: false,
        amount: false
    });
    const [quotationDiscountValidate, setQuotationDiscountValidate] = useState();
    const [quotationUpdatePublicNote, setQuotationUpdatePublicNote] = useState({
        quotationItemId: -1,
        publicNote: ''
    });

    const [modalPublicNote, setModalPublicNote] = useState('');

    useEffect(() => {
        let myitems = [];
        if (quotationDiscountType && quotationDiscountType.length > 0) {
            myitems = myitems.concat(quotationDiscountType);
        }

        myitems = myitems.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        setQuotationAddictionalProductsToAdd(myitems);
    }, [quotationDiscountType])

    useEffect(() => {
        var modalChangeMk = document.getElementById("quotationTO_ChangeMarkup");
        modalChangeMk.addEventListener('show.bs.modal', function (e) {
            callOnOpenChangeMarkup();
        });

        var modalChangeComm = document.getElementById("quotationTO_ChangeCommissionable");
        modalChangeComm.addEventListener('show.bs.modal', function (e) {
            callOnOpenChangeCommissionable();
        });

        var modalChangeIsComm = document.getElementById("quotationTO_ChangeIsCommissionable");
        modalChangeIsComm.addEventListener('show.bs.modal', function (e) {
            callOnOpenChangeIsCommissionable();
        });

        var modalChangeBuyPrice = document.getElementById("quotationTO_ChangeBuyPrice");
        modalChangeBuyPrice.addEventListener('show.bs.modal', function (e) {
            callOnOpenChangeBuyPrice();
        });

        var modalChangeAPIsSelected = document.getElementById("quotationTO_ChangeAPIsSelected");
        modalChangeAPIsSelected.addEventListener('show.bs.modal', function (e) {
            callOnOpenChangeAPIsSelected();
        });

        var modalQuotationChangeDiscount = document.getElementById("quotationTO_ManageDiscount");
        modalQuotationChangeDiscount.addEventListener('show.bs.modal', function (e) {
            var itemToApprove = props.recapItems.filter(x => x.idTipoSconto && x.discountStatus === 0)[0];
            itemToApprove.newSellAmount = null;
            setQuotationDiscountValidate(itemToApprove);
        });

        getQuotationDiscountType(props.recapItems);
    }, [props.recapItems]);

    function getQuotationDiscountType(myItems) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };


        let isAddOrder = false;
        if (myItems && myItems.length > 0) {
            isAddOrder = myItems.filter(i => i.wsBookingId).length > 0;
        }

        let excludeItems = [];
        if (myItems && myItems.length > 0) {

            if (!isAddOrder) {

                excludeItems = myItems.filter(i => i.productType === 'Generic' && i.idTipoSconto);

            } else {
                // In questo modo se sto aggiungendo nuovi prodotti ad un'ordine gia bookato
                // Posso inserire altri arrottondamenti che magari sono gia stati bookati precedentemente
                // escludendo gli arrotondamenti che non sono stati ancora bookati

                let addProds = myItems.filter(i => i.productType === 'Generic' && i.idTipoSconto && !i.wsBookingId);
                excludeItems = addProds;
            }

            excludeItems = excludeItems.map(i => i.idTipoSconto);
        }


        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationdiscounttype`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {

                if (myItems && myItems.length > 0)
                    item = item.filter(i => !excludeItems.includes(i.idTipoProdotto));

                setQuotationDiscountType(item);
            })
            .catch((err) => {
                console.error(err);
            });

    }

    function callOnOpenChangeMarkup() {
        var udpateMarkups = props.recapItems
            .filter(i => i.productType !== 'Generic' && !i.wsBookingId)
            .map((item) => {
                return {
                    id: item.id + "",
                    hadChange: false,
                    productType: item.productType,
                    productName: item.productName,
                    markupPercent: parseFloat(item.markupPercent),
                    markupAmount: parseFloat(item.markupAmount) - item.markupTaxesAmount,
                    markupTaxesAmount: parseFloat(item.markupTaxesAmount),
                    oldMarkupPercent: item.markupPercent,
                    oldMarkupAmount: item.markupAmount - item.markupTaxesAmount,
                    oldMarkupTaxesAmount: item.markupTaxesAmount,
                    buyAmount: parseFloat(item.buyBaseAmount),
                    taxAmount: item.taxAmount ? parseFloat(item.taxAmount) : 0
                }
            });

        setQuotationItemsMarkup(udpateMarkups);
    }

    function onChangeMarkupAmountTxt(event) {
        var udpateMarkups = quotationItemsMarkup.map((item) => {
            var itemId = event.target.id.replace('txtMarkupAmount_', '');
            if (item.id === itemId) {
                var mkAmount = event.target.value ? parseFloat(event.target.value) : 0;
                var buyPriceWithoutTaxes = item.buyAmount;
                if (item.taxAmount)
                    buyPriceWithoutTaxes = item.buyAmount - item.taxAmount + item.oldMarkupTaxesAmount;

                var mkPerc = parseFloat(mkAmount * 100 / buyPriceWithoutTaxes).toFixed(2);

                var mkTaxes = 0;
                if (item.taxAmount && item.oldMarkupTaxesAmount) {
                    mkTaxes = (item.taxAmount - item.oldMarkupTaxesAmount) * mkPerc / 100;
                }

                return {
                    ...item,
                    hadChange: true,
                    markupAmount: event.target.value ? mkAmount : '',
                    markupTaxesAmount: event.target.value ? parseFloat(mkTaxes) : '',
                    markupPercent: event.target.value ? parseFloat(mkPerc) : ''
                }
            }

            return item;
        });

        setQuotationItemsMarkup(udpateMarkups);
    }

    function onChangeMarkupPercentTxt(event) {
        var udpateMarkups = quotationItemsMarkup.map((item) => {
            var itemId = event.target.id.replace('txtMarkupPercent_', '');
            if (item.id === itemId) {
                var mkPerc = event.target.value ? parseFloat(event.target.value) : 0;
                var buyPriceWithoutTaxes = item.buyAmount;
                if (item.taxAmount)
                    buyPriceWithoutTaxes = item.buyAmount - item.taxAmount + item.oldMarkupTaxesAmount;

                var mkAmount = parseFloat(buyPriceWithoutTaxes * mkPerc / 100).toFixed(2);

                var mkTaxes = 0;
                if (item.taxAmount && item.oldMarkupTaxesAmount) {
                    mkTaxes = (item.taxAmount - item.oldMarkupTaxesAmount) * mkPerc / 100;
                }

                return {
                    ...item,
                    hadChange: true,
                    markupAmount: event.target.value ? parseFloat(mkAmount) : '',
                    markupTaxesAmount: event.target.value ? parseFloat(mkTaxes) : '',
                    markupPercent: event.target.value ? mkPerc : ''
                }
            }

            return item;
        });

        setQuotationItemsMarkup(udpateMarkups);
    }

    function saveChangeMarkup() {
        var udpateMarkups = quotationItemsMarkup
            .filter((item) => item.hadChange)
            .map((item) => {
                return {
                    quotationItemId: item.id,
                    percent: item.markupPercent ? item.markupPercent : 0,
                    value: item.markupAmount ? item.markupAmount : 0
                }
            });

        props.callSaveChangeMarkup(udpateMarkups);
    }

    function callOnOpenChangeCommissionable() {
        var udpateCommissionable = props.recapItems
            .filter(x => x.isCommissionable && !x.wsBookingId)
            .map((item) => {
                return {
                    id: item.id + "",
                    hadChange: false,
                    productType: item.productType,
                    productName: item.productName,
                    commissionablePercent: parseFloat(item.commissionablePercent),
                    commissionableAmount: parseFloat(item.commissionableAmount),
                    oldCommissionablePercent: item.commissionablePercent,
                    oldCommissionableAmount: item.commissionableAmount,
                    isCommissionable: item.isCommissionable,
                    netAmount: parseFloat(item.netBaseAmount),
                    taxAmount: item.taxAmount ? parseFloat(item.taxAmount) : 0
                }
            });

        setQuotationItemsCommissionable(udpateCommissionable);
    }

    function onChangeCommissionableAmountTxt(event) {
        var udpateCommissionables = quotationItemsCommissionable.map((item) => {
            var itemId = event.target.id.replace('txtCommissionableAmount_', '');
            if (item.id === itemId) {
                var commAmount = event.target.value ? parseFloat(event.target.value) : 0;
                var netAmount = item.netAmount;
                if (commAmount > 0 && item.taxAmount)
                    netAmount = netAmount - item.taxAmount;

                var commPerc = parseFloat(commAmount * 100 / netAmount).toFixed(2);

                return {
                    ...item,
                    hadChange: true,
                    commissionableAmount: event.target.value ? commAmount : '',
                    commissionablePercent: event.target.value ? parseFloat(commPerc) : ''
                }
            }

            return item;
        });

        setQuotationItemsCommissionable(udpateCommissionables);
    }

    function onChangeCommissionablePercentTxt(event) {
        var udpateCommissionables = quotationItemsCommissionable.map((item) => {
            var itemId = event.target.id.replace('txtCommissionablePercent_', '');
            if (item.id === itemId) {
                var commPerc = event.target.value ? parseFloat(event.target.value) : 0;

                var netAmount = item.netAmount;
                if (commPerc > 0 && item.taxAmount)
                    netAmount = netAmount - item.taxAmount;

                var commAmount = parseFloat(netAmount * commPerc / 100).toFixed(2);

                return {
                    ...item,
                    hadChange: true,
                    commissionableAmount: event.target.value ? commAmount : '',
                    commissionablePercent: event.target.value ? commPerc : ''
                }
            }

            return item;
        });

        setQuotationItemsCommissionable(udpateCommissionables);
    }

    function saveChangeCommissionable() {
        var udpateCommissionables = quotationItemsCommissionable
            .filter((item) => item.hadChange)
            .map((item) => {
                return {
                    quotationItemId: item.id,
                    productType: item.productType,
                    amount: item.commissionablePercent ? item.commissionablePercent : 0
                }
            });

        props.callSaveChangeCommissionable(udpateCommissionables);
    }

    function callOnOpenChangeIsCommissionable() {
        var udpateIsCommissionable = props.recapItems
            .filter(x => !x.wsBookingId && !x.idTipoSconto && (x.productType !== 'Generic' || (x.productType === 'Generic' && x.isSelected)))
            .map((item) => {
                return {
                    id: item.id + "",
                    hadChange: false,
                    productType: item.productType,
                    productName: item.productName,
                    isCommissionable: item.isCommissionable
                }
            });

        setQuotationItemsIsCommissionable(udpateIsCommissionable);
    }

    function onChangeIsCommissionable(event) {
        var udpateIsCommissionable = quotationItemsIsCommissionable.map((item) => {
            var itemId = event.target.id.replace('cbUpdateIsCommissionable_', '');
            if (item.id === itemId) {
                return {
                    ...item,
                    hadChange: true,
                    isCommissionable: event.target.checked
                }
            }

            return item;
        });

        setQuotationItemsIsCommissionable(udpateIsCommissionable);
    }

    function saveChangeIsCommissionable() {
        var udpateIsCommissionables = quotationItemsIsCommissionable
            .filter((item) => item.hadChange)
            .map((item) => {
                return {
                    quotationItemId: item.id,
                    productType: item.productType,
                    isCommissionable: item.isCommissionable
                }
            });

        props.callSaveChangeIsCommissionable(udpateIsCommissionables);
    }

    function callOnOpenChangeBuyPrice() {
        var udpateBuyPrices = props.recapItems
            .filter(i => i.productType !== 'Generic' && !i.wsBookingId)
            .map((item) => {
                return {
                    id: item.id + "",
                    productName: item.productName,
                    hadChange: false,
                    productType: item.productType,
                    buyAmount: item.buyAmount,
                    oldBuyAmount: item.buyAmount,
                    buyCurrency: item.buyCurrency,
                    buyOriginalAmount: parseFloat(item.buyOriginalAmount),
                    changeAmount: item.changeAmount,
                    oldChangeAmount: item.changeAmount,
                    changeCurrency: item.changeCurrency
                }
            });

        setQuotationItemsBuyPrice(udpateBuyPrices);
    }

    function onChangeBuyChangeCurrencyTxt(event) {
        var udpateBuyPrices = quotationItemsBuyPrice.map((item) => {
            var itemId = event.target.id.replace('txtBuyPriceCurrency_', '');
            if (item.id === itemId) {
                var changeAmount = event.target.value ? parseFloat(event.target.value) : 0;
                var newAmount = item.buyOriginalAmount / changeAmount;

                return {
                    ...item,
                    hadChange: true,
                    buyAmount: event.target.value ? parseFloat(newAmount).toFixed(2) : '',
                    changeAmount: event.target.value ? changeAmount : ''
                }
            }

            return item;
        });

        setQuotationItemsBuyPrice(udpateBuyPrices);
    }

    function onSaveBuyPrice() {
        var udpateBuyPrices = quotationItemsBuyPrice
            .filter((item) => item.hadChange)
            .map((item) => {
                return {
                    quotationItemId: item.id,
                    changeAmount: item.changeAmount
                }
            });

        props.callSaveBuyPrice(udpateBuyPrices);
    }

    function onClickOpenUpdateManualProduct(pType, qId, pSubType) {
        props.callOpenUpdateManualProduct(pType, qId, pSubType);
    }

    function onClickOpenManualProductDescription(item) {
        setModalPublicNote(item.publicNote);

        let btn = document.getElementById('btnOpenManualProductDescription');
        btn.click();
    }

    function callOnOpenChangeAPIsSelected() {
        var udpateIsSelected = props.recapItems
            .filter(i => i.productType === 'Generic' && !i.isMandatory && !i.idTipoSconto && (!i.wsBookingId || i.alternativeSellAmount))
            .map((item) => {
                let isAlternative = false;
                if (item.wsBookingId && item.alternativeSellAmount)
                    isAlternative = true;

                return {
                    id: item.id + "",
                    hadChange: false,
                    productName: item.productName,
                    isSelected: item.isSelected,
                    isAlternative: isAlternative,
                    alternativeProductIds: item.alternativeProductIds
                }
            });

        setQuotationAPIsSelected(udpateIsSelected);
    }

    function onChangeAPIsSelected(event) {
        var udpateIsSelected = quotationAPIsSelected.map((item) => {
            var itemId = event.target.id.replace('cbUpdateIsSelected_', '');
            if (item.id === itemId) {
                return {
                    ...item,
                    hadChange: true,
                    isSelected: event.target.checked
                }
            }

            return item;
        });

        setQuotationAPIsSelected(udpateIsSelected);
    }

    function saveChangeAPIsSelected() {
        var udpateIsSelected = quotationAPIsSelected
            .filter((item) => item.hadChange)
            .map((item) => {
                return {
                    quotationItemId: item.id,
                    isSelected: item.isSelected,
                    isAlternative: item.isAlternative,
                    alternativeProductIds: item.alternativeProductIds
                }
            });

        props.callSaveChangeAPIsSelected(udpateIsSelected);
    }

    function onChangeAddDiscount(event, propertyName) {
        let newAddQuotationDiscount = { ...addQuotationDiscount };
        let newAddQuotationDiscountIsValid = { ...addQuotationDiscountIsValid };

        switch (propertyName) {
            case 'amount': {
                let myValue = event.target.value;
                newAddQuotationDiscount.amount = myValue;
                newAddQuotationDiscount.currency = 'EUR';
                newAddQuotationDiscountIsValid.amount = myValue !== null && myValue !== undefined && myValue !== '';
                break;
            }
            case 'description': {
                let myValue = event.target.value;
                newAddQuotationDiscount.descrizione = myValue;
                break;
            }
            case 'discountType': {
                newAddQuotationDiscountIsValid.discountType = true;
                newAddQuotationDiscount.idProdotto = event;
                break;
            }
        }

        setAddQuotationDiscount(newAddQuotationDiscount);
        setAddQuotationDiscountIsValid(newAddQuotationDiscountIsValid);
    }

    function onCallQuotationAddDiscount() {
        props.callQuotationAddDiscount(addQuotationDiscount);
    }

    function onChangeDiscountValidate(event) {
        setQuotationDiscountValidate({ ...quotationDiscountValidate, newSellAmount: event.target.value });
    }

    function onSaveConfirmDiscount(status) {
        var inputData = {
            idProdottoCorrelato: quotationDiscountValidate.id,
            status: status,
            amount: quotationDiscountValidate.newSellAmount
        };

        props.callSaveConfirmDiscount(inputData);
    }

    function onCallDeleteBuonoSconto(discountId) {
        props.onDeleteBuonoSconto(discountId);
    }

    function onClickOpenAddPublicNote(quotationItemId, publicNote, serviceNote, description, canAddDescription) {
        setQuotationUpdatePublicNote({ quotationItemId: quotationItemId, publicNote: publicNote, serviceNote: serviceNote, description: description, canAddDescription: canAddDescription });

        let modalPublicNote = document.getElementById('btnOpenModalAddPublicNote');
        modalPublicNote.click();
    }

    function onBlurUpdatePublicNote(event) {
        setQuotationUpdatePublicNote({
            ...quotationUpdatePublicNote,
            publicNote: event.target.value
        });
    }

    function onBlurEventServiceNote(roomIndex, propertyName, event) {
        setQuotationUpdatePublicNote({
            ...quotationUpdatePublicNote,
            serviceNote: event
        });
    }

    function onBlurEventDescription(event, propertyName) {
        setQuotationUpdatePublicNote({
            ...quotationUpdatePublicNote,
            description: event
        });
    }

    function onSavePublicNote() {
        props.callSaveQuotationItemPublicNote(quotationUpdatePublicNote);
    }

    return (
        <>
            <div key="quoationRecapItem" className="border border-gray-300 rounded mb-4">
                <div className="p-4">
                    <div className="">
                        <table className="table-hover p-0 h075 w-100">
                            <thead>
                                <tr key="header_recapItem">
                                    <th scope="col">{t("MyBook:Type")}</th>
                                    <th scope="col">{t("MyBook:Product")}</th>
                                    <th scope="col">{t("MyBook:Period")}</th>
                                    <th scope="col">
                                        {t("MyBook:Price/Change")}
                                        <a data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeBuyPrice">
                                            <M3Icon iconName="Operation" externalClass="text-gray-300 h085r" />
                                        </a>
                                    </th>
                                    <th scope="col">
                                        Markup
                                        <a data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeMarkup">
                                            <M3Icon iconName="Operation" externalClass="text-gray-300 h085r" />
                                        </a>
                                    </th>
                                    <th scope="col">
                                        {t("MyBook:Commission")}
                                        <a data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeCommissionable">
                                            <M3Icon iconName="Operation" externalClass="text-gray-300 h085r" />
                                        </a>
                                    </th>
                                    <th scope="col">{t("MyBook:Sale")}</th>
                                    <th className="d-flex align-items-end">
                                        Com.
                                        <a data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeIsCommissionable">
                                            <M3Icon iconName="Operation" externalClass="text-gray-300 h085r" />
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.recapItems && props.recapItems.map((item, key) => {
                                        return <React.Fragment key={key}>
                                            {
                                                (key === 0 || item.step !== props.recapItems[key - 1].step) && <tr className="bg-light p-0">
                                                    <td colSpan="8">
                                                        {item.step > -1 && <>Step {item.step} {item.stepName && <span> - {item.stepName}</span>}</>}
                                                        {item.step === -1 && "Step polizze, quote part etc"}
                                                        {item.step === -2 && "Sconti, interventi commerciali"}
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={"p-0 " + (item.step === -1 && !item.isSelected ? "bg-light-hover" : "") + (!item.wsBookingId ? "" : " bg-gray-200")}>
                                                <th scope="row">
                                                    <M3IconProductType productType={item.productType} productSubtype={item.productSubType} externalClass={!item.wsBookingId ? "text-gray-300" : "text-dark"} />
                                                </th>
                                                <td className="td-product-name">
                                                    <div className="d-flex align-items-lg-start">
                                                        {item.productName}
                                                        {
                                                            item.isManualLoading && !item.wsBookingId
                                                                ? <button className="border-0 bg-transparent" onClick={_ => onClickOpenUpdateManualProduct(item.productType, item.id, item.productSubType)}><M3Icon iconName="Edit" externalClass="text-gray-300 h085" /></button>
                                                                : ""
                                                        }
                                                        {
                                                            item.publicNote && item.productType === "Generic" && <>
                                                                <button className="border-0 bg-transparent"
                                                                    onClick={_ => onClickOpenManualProductDescription(item)}>
                                                                    <M3Icon iconName="Info" externalClass="text-gray-300 h085" />
                                                                </button>
                                                            </>
                                                        }
                                                        {
                                                            !item.wsBookingId && item.productType === "Generic" && !item.idTipoSconto && item.isMandatory && !item.isManualLoading && <div className="ml-1">({t("MyBook:Mandatory")})</div>
                                                        }
                                                        {
                                                            !item.wsBookingId && item.productType === "Generic" && !item.idTipoSconto && !item.isMandatory && item.isManualLoading && <div className="ml-1">(Prodotto Manuale)</div>
                                                        }
                                                        {
                                                            item.wsBookingId && item.productType === "Generic" && !item.idTipoSconto && !item.isMandatory && item.isManualLoading && <div className="ml-1">({t("MyBook:ManualProductReservated")})</div>
                                                        }
                                                        {
                                                            !item.wsBookingId && item.idTipoSconto && <div className="ml-1">({translateQuotationDiscountStatus(item.discountStatus)})</div>
                                                        }
                                                    </div>
                                                    {
                                                        item.isManualLoading && item.wsBookingId && <small>({t("MyBook:ManualProductReservated")})</small>
                                                    }
                                                    {
                                                        !item.isManualLoading && item.wsBookingId && !item.alternativeSellAmount && <small>({t("MyBook:ProductReservated")})</small>
                                                    }
                                                    {
                                                        !item.isManualLoading && item.wsBookingId && item.alternativeSellAmount && !item.isSelected && <small>({t("MyBook:ProductReservatedVariation")})</small>
                                                    }
                                                    {
                                                        !item.isManualLoading && item.wsBookingId && item.alternativeSellAmount && item.isSelected && <small>({t("MyBook:ProductSubstitueted")})</small>
                                                    }
                                                    {
                                                        item.productType !== "Generic" && !item.isManualLoading && !item.wsBookingId && <button className="border-0 bg-transparent" onClick={event => onClickOpenAddPublicNote(item.id, item.publicNote, item.serviceNote, item.description, item.canAddDescription)}>
                                                            <M3Icon iconName="AddNote" externalClass="h085 text-gray-200" />
                                                        </button>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.productType !== "Generic" && <>
                                                            <div>{formatDateTime(item.dateFrom, props.cultureName, { twoDigits: true })}</div>
                                                            {
                                                                item.dateTo
                                                                    ? <div>{formatDateTime(item.dateTo, props.cultureName, { twoDigits: true })}</div>
                                                                    : <></>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !item.idTipoSconto && item.step !== -2 && <>
                                                            <div>{formatPrice(item.buyAmount, item.buyCurrency, props.cultureName)}</div>
                                                            {
                                                                item.changeCurrency !== item.buyCurrency && <div>{formatPrice(item.buyOriginalAmount, item.changeCurrency, props.cultureName)}</div>
                                                            }
                                                            <div>{item.changeCurrency}/{item.buyCurrency} {formatNumber(item.changeAmount, props.cultureName)}</div>
                                                        </>
                                                    }
                                                    {
                                                        item.extrasBuyPriceAmount && item.extrasBuyPriceAmount > 0 && <div className="mt-1">(Ancillaries:{formatPrice(item.extrasBuyPriceAmount, item.extrasCurrency, props.cultureName)})</div>
                                                    }
                                                    {
                                                        item.supplierCommissionAmount && item.supplierCommissionAmount > 0 && <div className="mt-1">(Comm. Suppl:{formatPrice(item.supplierCommissionAmount, item.sellCurrency, props.cultureName)})</div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.productType !== "Generic" && <><div>{item.markupPercent}%</div><div>{formatPrice(item.markupAmount, item.markupCurrency, props.cultureName)}</div></>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !item.idTipoSconto && item.step !== -2 && <>
                                                            <div>{item.commissionablePercent}%</div>
                                                            {(configData.Settings.QuotationTO.ShowIVARecaptItem || !item.commissionableAmountWithoutIVA) && <div>{formatPrice(item.commissionableAmount, item.commissionableCurrency, props.cultureName)}</div>}
                                                            {!configData.Settings.QuotationTO.ShowIVARecaptItem && item.commissionableAmountWithoutIVA && <div>* {formatPrice(item.commissionableAmountWithoutIVA, item.commissionableCurrency, props.cultureName)}</div>}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {item.alternativeSellAmount && <div className="h6" alt="Nuovo prezzo">{formatPrice(item.alternativeSellAmount, item.sellCurrency, props.cultureName)}</div>}
                                                    <div className={"h6 " + (item.alternativeSellAmount ? ' text-decoration-line-through' : '')} alt={(item.alternativeSellAmount ? ' Vecchio prezzo' : '')}>{formatPrice(item.sellAmount, item.sellCurrency, props.cultureName)}</div>
                                                    {
                                                        item.productType === "Flight" || item.productType === "Cruise"
                                                            ? <div>(Tax:{formatPrice(item.taxAmount, item.taxCurrency, props.cultureName)})</div>
                                                            : <></>
                                                    }
                                                    {
                                                        item.extrasAmount && item.extrasAmount > 0 && <div className="mt-1">(Ancillaries:{formatPrice(item.extrasAmount, item.extrasCurrency, props.cultureName)})</div>
                                                    }
                                                    {
                                                        item.onSpotAmount && item.onSpotAmount > 0 && <div className="mt-1">( {item.productType === 'Cruise' ? "QDS" : "In Loco"}:{formatPrice(item.onSpotAmount, item.onSpotCurrency, props.cultureName)})</div>
                                                    }
                                                </td>
                                                <td className="d-flex align-items-end">
                                                    {!item.idTipoSconto && <input type="checkbox" className="form-check" checked={item.isCommissionable} disabled={true} />}
                                                    {
                                                        item.productType === "Generic" && (!item.wsBookingId || item.alternativeSellAmount) && !item.idTipoSconto && !item.isMandatory && <button className="btn btn-sm py-0" data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeAPIsSelected">
                                                            {item.isSelected ? <M3Icon iconName="RemoveCircle" externalClass="text-danger h085" /> : <M3Icon iconName="AddCircle" externalClass="text-danger h085" />}
                                                        </button>
                                                    }
                                                    {
                                                        item.idTipoSconto && item.discountStatus === 0 && <a data-bs-toggle="modal" data-bs-target="#quotationTO_ManageDiscount">
                                                            <M3Icon iconName="Check" externalClass="text-primary h085" />
                                                        </a>
                                                    }
                                                    {
                                                        item.idTipoSconto && !item.wsBookingId && <button className="btn btn-sm py-0" onClick={e => onCallDeleteBuonoSconto(item.id)}>
                                                            <M3Icon iconName="RemoveCircle" externalClass="text-danger h085" />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        </React.Fragment>;
                                    })
                                }
                            </tbody>
                        </table>
                        <table className="w-100">
                            <tbody>
                                {
                                    quotationAddictionalProductsToAdd && quotationAddictionalProductsToAdd.length > 0 && <tr>
                                        <td colSpan="2" className="w-25">
                                            <span className="p-float-label form-select-label-m3-react-prime">
                                                {
                                                    quotationAddictionalProductsToAdd && quotationAddictionalProductsToAdd.length > 0 && <Dropdown value={addQuotationDiscount.idProdotto}
                                                        onChange={(e) => onChangeAddDiscount(e.value, 'discountType')}
                                                        inputId="dd-addProdType"
                                                        filter
                                                        className="w-100 form-select-m3-react-prime"
                                                        options={quotationAddictionalProductsToAdd.map(d => { return { label: d.name, value: d.idProdotto }; })}
                                                        optionLabel="label" />
                                                }
                                                <label htmlFor="dd-addProdType">{t("MyBook:DiscountType")}</label>
                                            </span>
                                        </td>
                                        <td colSpan="3" className="w-50">
                                            <div className="form-floating mt-2 mb-2 border">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Descrizione"
                                                    id="floatingTextarea2"
                                                    onChange={e => onChangeAddDiscount(e, 'description')}
                                                ></textarea>
                                                <label className="active" htmlFor="floatingSelect">{t("MyBook:Description")}</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-floating mt-2 mb-2">
                                                <input type="number"
                                                    className="form-control"
                                                    placeholder="Importo"
                                                    id="floatingSelect"
                                                    onChange={e => onChangeAddDiscount(e, 'amount')}
                                                />
                                                <label htmlFor="floatingSelect">{t("MyBook:Amount")}</label>
                                            </div>
                                        </td>
                                        <td colSpan="2" className="text-center align-bottom">
                                            <div className="form-floating mt-2 mb-2">
                                                <button
                                                    className="btn btn-sm btn-tertiary"
                                                    onClick={_ => onCallQuotationAddDiscount()}
                                                    disabled={!(addQuotationDiscountIsValid.discountType && addQuotationDiscountIsValid.amount)}
                                                >
                                                    <span className="far fa-paper-plane"></span> {t("MyBook:Request")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ChangeMarkup" tabIndex="-1" aria-labelledby="quotationTO_ChangeMarkupLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeMarkupLabel">
                                Modifica Markup
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">{t("MyBook:Type")}</th>
                                        <th scope="col">{t("MyBook:Product")}</th>
                                        <th scope="col">{t("MyBook:Value")}</th>
                                        <th scope="col">%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quotationItemsMarkup && quotationItemsMarkup.length > 0
                                            ? quotationItemsMarkup.map((item) => {
                                                return <React.Fragment key={item.id} >
                                                    <tr className="p-0">
                                                        <th scope="row">
                                                            <M3IconProductType productType={item.productType} productSubtype={item.productSubType} />
                                                        </th>
                                                        <td>
                                                            <div className="d-flex align-items-lg-start">{item.productName}</div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group input-group-sm mb-3">
                                                                <span className="input-group-text w-35" id="inputGroup-sizing-sm">{item.oldMarkupAmount}</span>
                                                                <input id={"txtMarkupAmount_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.markupAmount} placeholder={item.markupAmount} onChange={onChangeMarkupAmountTxt} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group input-group-sm mb-3">
                                                                <span className="input-group-text" id="inputGroup-sizing-sm">{item.oldMarkupPercent + "%"}</span>
                                                                <input id={"txtMarkupPercent_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.markupPercent} placeholder={item.markupPercent + "%"} onChange={onChangeMarkupPercentTxt} />
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    {
                                                        item.oldMarkupTaxesAmount ? <tr className="p-0">
                                                            <th scope="row"></th>
                                                            <td className="border-0"></td>
                                                            <td className="border-0">
                                                                <div><small>Tasse</small></div>
                                                                <div className="input-group input-group-sm mb-3">
                                                                    <span className="input-group-text w-35" id="inputGroup-sizing-sm">{item.oldMarkupTaxesAmount}</span>
                                                                    <input id={"txtMarkupAmount_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.markupTaxesAmount} placeholder={item.markupTaxesAmount} readOnly="true" onChange={onChangeMarkupAmountTxt} />
                                                                </div>
                                                            </td>
                                                            <td className="border-0"></td>
                                                        </tr> : <></>
                                                    }
                                                </React.Fragment>;
                                            })
                                            : <tr className="p-0"><td>{t("MyBook:MakrupModifiedText")}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">{t("MyBook:SaveModifiedQuotationText")}.</div>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("MyBook:CloseWithoutSave")}</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={saveChangeMarkup}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ChangeCommissionable" tabIndex="-1" aria-labelledby="quotationTO_ChangeCommissionableLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeCommissionableLabel">
                                {t("MyBook:CommissionModify")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">{t("MyBook:Type")}</th>
                                        <th scope="col">{t("MyBook:Product")}</th>
                                        <th scope="col">{t("MyBook:Value")}</th>
                                        <th scope="col">%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quotationItemsCommissionable && quotationItemsCommissionable.length > 0
                                            ? quotationItemsCommissionable.map((item) => {
                                                return <tr key={item.id} className="p-0">
                                                    <th scope="row"><M3IconProductType productType={item.productType} productSubtype={item.productSubType} /></th>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">{item.productName}</div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group input-group-sm mb-3">
                                                            <span className="input-group-text w-35" id="inputGroup-sizing-sm">{item.oldCommissionableAmount}</span>
                                                            <input id={"txtCommissionableAmount_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.commissionableAmount} placeholder={item.commissionableAmount} onChange={onChangeCommissionableAmountTxt} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group input-group-sm mb-3">
                                                            <span className="input-group-text" id="inputGroup-sizing-sm">{item.oldCommissionablePercent + "%"}</span>
                                                            <input id={"txtCommissionablePercent_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.commissionablePercent} placeholder={item.commissionablePercent + "%"} onChange={onChangeCommissionablePercentTxt} />
                                                        </div>
                                                    </td>
                                                </tr>;
                                            })
                                            : <tr className="p-0"><td> {t("MyBook:NotExistProductCommissionModified")}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">Al salvataggio verranno applicate le modifiche e ricalcolato il preventivo.</div>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={saveChangeCommissionable}>{t('Button:Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ChangeIsCommissionable" tabIndex="-1" aria-labelledby="quotationTO_ChangeIsCommissionableLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeIsCommissionableLabel">
                                Modifica Commissionabile
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Prodotto</th>
                                        <th scope="col">Commissionabile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quotationItemsIsCommissionable && quotationItemsIsCommissionable.length > 0
                                            ? quotationItemsIsCommissionable.map((item) => {
                                                return <tr key={item.id} className="p-0">
                                                    <th scope="row"><M3IconProductType productType={item.productType} productSubtype={item.productSubType} /></th>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">{item.productName}</div>
                                                    </td>
                                                    <td>
                                                        <input id={"cbUpdateIsCommissionable_" + item.id} key={item.id} type="checkbox" checked={item.isCommissionable} onChange={onChangeIsCommissionable} />
                                                    </td>
                                                </tr>;
                                            })
                                            : <tr className="p-0"><td>Non ci sono prodotti modificabili</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">Al salvataggio verranno applicate le modifiche e ricalcolato il preventivo.</div>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={saveChangeIsCommissionable}>{t('Button:Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ChangeBuyPrice" tabIndex="-1" aria-labelledby="quotationTO_ChangeBuyPriceLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeBuyPriceLabel">
                                Modifica Costo
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Prodotto</th>
                                        <th scope="col">Valore</th>
                                        <th scope="col">Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quotationItemsBuyPrice && quotationItemsBuyPrice.length > 0
                                            ? quotationItemsBuyPrice.map((item) => {
                                                return <tr key={item.id} className="p-0">
                                                    <th scope="row"><M3IconProductType productType={item.productType} productSubtype={item.productSubType} /></th>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">{item.productName}</div>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.changeCurrency !== item.buyCurrency && <span>Costo valuta fornitore: {formatPrice(item.buyOriginalAmount, item.changeCurrency, props.cultureName)}</span>
                                                        }
                                                        <div className="input-group input-group-sm mb-3">
                                                            <span className="input-group-text w-35" id="inputGroup-sizing-sm">{item.oldBuyAmount}</span>
                                                            <input id={"txtBuyPriceAmount_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.buyAmount} placeholder={item.buyAmount} disabled={true} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group input-group-sm mb-3">
                                                            <span className="input-group-text" id="inputGroup-sizing-sm">{formatNumber(item.oldChangeAmount, props.cultureName)} {item.changeCurrency}/{item.buyCurrency}</span>
                                                            <input id={"txtBuyPriceCurrency_" + item.id} key={item.id} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={item.changeAmount} placeholder={item.changeAmount} onChange={onChangeBuyChangeCurrencyTxt} />
                                                        </div>
                                                    </td>
                                                </tr>;
                                            })
                                            : <tr className="p-0"><td>Non ci sono prodotti con costi modificabili</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">Al salvataggio verranno applicate le modifiche e ricalcolato il preventivo.</div>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onSaveBuyPrice}>{t('Button:Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ChangeAPIsSelected" tabIndex="-1" aria-labelledby="quotationTO_ChangeAPIsSelectedLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeAPIsSelectedLabel">
                                Seleziona Prodotti aggiuntivi
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Prodotto</th>
                                        <th scope="col">Selezionato</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quotationAPIsSelected && quotationAPIsSelected.length > 0
                                            ? quotationAPIsSelected.map((item) => {
                                                return <tr key={item.id} className="p-0">
                                                    <th scope="row">
                                                        <M3IconProductType productType={item.productType} productSubtype={item.productSubType} />
                                                    </th>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">{item.productName}</div>
                                                    </td>
                                                    <td>
                                                        <input id={"cbUpdateIsSelected_" + item.id} key={item.id} type="checkbox" checked={item.isSelected} onChange={onChangeAPIsSelected} />
                                                    </td>
                                                </tr>;
                                            })
                                            : <tr className="p-0"><td>Non ci sono prodotti modificabili</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">Al salvataggio verranno applicate le modifiche e ricalcolato il preventivo.</div>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={saveChangeAPIsSelected}>{t('Button:Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quotationTO_ManageDiscount" tabIndex="-1" aria-labelledby="quotationTO_ManageDiscountLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_ChangeAPIsSelectedLabel">
                                Intervento Commerciale
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="mybook-table table-hover p-0 xx-small ">
                                <thead>
                                    <tr>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Valore</th>
                                        <th scope="col">Nuovo Valore</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-lg-start">
                                                {quotationDiscountValidate && quotationDiscountValidate.productName}
                                            </div>
                                        </td>
                                        <td>
                                            {quotationDiscountValidate && formatPrice(quotationDiscountValidate.sellAmount, quotationDiscountValidate.sellCurrency, props.cultureName)}
                                        </td>
                                        <td>
                                            {
                                                quotationDiscountValidate && <div className="input-group input-group-sm mb-3">
                                                    <input id="qd_Validate"
                                                        type="number"
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={quotationDiscountValidate.newSellAmount}
                                                        placeholder={quotationDiscountValidate.sellAmount}
                                                        onChange={e => onChangeDiscountValidate(e)} />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100 text-right">Al salvataggio verranno applicate le modifiche e ricalcolato il preventivo.</div>
                            <div>
                                <button type="button" className="btn bt-sm tp-btn-confirm mr-1" data-bs-dismiss="modal" onClick={e => onSaveConfirmDiscount(1)}>Conferma</button>
                                <button type="button" className="btn bt-sm tp-btn-manage mr-1" data-bs-dismiss="modal" onClick={e => onSaveConfirmDiscount(2)}>Rifiuta</button>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a id="btnOpenModalAddPublicNote" data-bs-toggle="modal" data-bs-target="#quotationTO_AddPublicNote"></a>
            <div className="modal fade" id="quotationTO_AddPublicNote" tabIndex="-1" aria-labelledby="quotationTO_AddPublicNoteLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="quotationTO_AddPublicNoteLabel">
                                Inserisci le tue note
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="pb-2 border-light border-bottom">
                                {
                                    quotationUpdatePublicNote.canAddDescription && <AddServiceNote
                                        serviceNote={quotationUpdatePublicNote.description}
                                        roomIndex={-1}
                                        noteTypeToAdd={12}
                                        onBlurEvent={onBlurEventDescription} />
                                }
                            </div>

                            <div className="pb-2">
                                <AddServiceNote
                                    serviceNote={quotationUpdatePublicNote.serviceNote}
                                    roomIndex={0}
                                    onBlurEvent={onBlurEventServiceNote} />
                            </div>

                            <div class="mt-2 pt-2 border-light border-top">
                                <div className="row">
                                    <div className="col-12 form-floating ">
                                        <textarea className="form-control"
                                            placeholder="Note Interne"
                                            value={quotationUpdatePublicNote.publicNote}
                                            onChange={(e) => { onBlurUpdatePublicNote(e); }}
                                            id="floatingTextareaIntNote"
                                            style={{ height: '100px' }} >
                                        </textarea>
                                        <label htmlFor="floatingTextareaIntNote">Note Interne</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <div>
                                <button type="button" className="btn bt-sm tp-btn-confirm mr-1" data-bs-dismiss="modal" onClick={e => onSavePublicNote()}>{t('Button:Save')}</button>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi senza salvare</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a id="btnOpenManualProductDescription" data-bs-toggle="modal" data-bs-target="#openManualProductDescription"></a>
            <div className="modal fade" id="openManualProductDescription" tabIndex="-1" aria-labelledby="openManualProductDescriptionLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="openManualProductDescription">
                                Note
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {modalPublicNote}
                        </div>
                        <div className="modal-footer">
                            <div>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}