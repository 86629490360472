import React from 'react';
import { getIconUrlToPrint } from "../../../../js/PrintUtility";
import { getCDNLogoDocument } from "../../../../js/CDNUtility";

export const FooterFirstPagePDFPrint = ({ title, extraInfo }) => {
    return (<>
        <table>
            <tr className="row" style={{ borderTop: '1px solid #DAB451', marginTop: '10px', }}>
                <td style={{ borderRight: '1px solid #DAB451', marginTop: '3px', marginBottom: '3px' }}>
                    <span style={{ textAlign: 'center' }}>
                        <h6>
                            GOING SRL – Sede Legale Piazzale Lotto 2<br />
                            20148 Milano (MI) – Tel. 02.881261, Fax 02.87152977<br />
                            Capitale Sociale Euro 100.000,00 i.v. – P.IVA e Codice Fiscale 05197460966<br />
                            R.E.A. 1803899 – MI Società a Socio Unico<br />
                            Società soggetta a direzione e coordinamento<br />
                            di MSC Mediterranean Shipping Company SA – www.going.it<br />
                        </h6>
                    </span>
                </td>
                <td>
                    {extraInfo.customerInfo &&
                        <h6>
                            {extraInfo.customerInfo.ragioneSociale}<br />
                            {extraInfo.customerInfo.address} <br />
                            {extraInfo.customerInfo.zipCode} {extraInfo.customerInfo.city} <br />
                            {extraInfo.customerInfo.phone && <>
                                Tel. {extraInfo.customerInfo.phone} <br />
                            </>}
                            {extraInfo.customerInfo.email && <>
                                Mail {extraInfo.customerInfo.email} <br />
                            </>}
                        </h6>
                    }
                </td>
            </tr>
        </table>
    </>
    )
}
