import React from 'react';
import { getIconUrlToPrint } from "../../../js/PrintUtility";
import Cookies from 'universal-cookie';
import { getCDNLogoDocument } from "../../../js/CDNUtility";
import { BookParameterId } from '../../../js/Constant';
import { formatDateTime } from '../../../js/Utils.js';

export const HeaderFirstPagePDFPrint = ({ title, extraInfo }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function getRefPax(paxes) {
        let pax = "";

        for (var i = 0; i < paxes.length; i++) {
            if (paxes[i].isRefPax) {
                pax = paxes[i].bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue + ' ' +
                    paxes[i].bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].bpValue;
                break;
            }
        }

        return pax;
    }
        
    function getDate(itemStep) {
        let dateFrom = "";
        let dateTo = "";

        if (itemStep[0].dateFrom) {
            dateFrom = formatDateTime(itemStep[0].dateFrom, cultureName);
        }
        if (itemStep[itemStep.length - 1].dateTo) {
            dateTo = formatDateTime(itemStep[0].dateTo, cultureName);
        }

        
        return 'DAL '+ dateFrom +' - AL '+ dateTo;
    }

    return (<>
        <table>
            <tr className="col-lg-12">
                <td className="col-lg-4">
                    <img src={getCDNLogoDocument()} className="logo-header" alt="Going Logo" />
                </td>
                <td className="col-lg-4" style={{ borderLeft: '1px solid #DAB451', borderRight: '1px solid #DAB451', marginTop: '10px', }}>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", }} >
                        <td className="badge-customer text-white" style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
                            <div style={{fontSize: '7px',}}>CLIENTE</div>
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", paddingTop: '0px', paddingBottom: '0px' }}>
                        <td style={{ fontSize: '8px', textAlign: 'center', marginTop: '-5px' }}>
                            {extraInfo && extraInfo.paxes &&
                                <h6>{getRefPax(extraInfo.paxes)}</h6>
                            }
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", }}>
                        <td className="badge-customer text-white" style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', }}>
                            <div style={{ fontSize: '7px' }}>DATA DEL VIAGGIO</div>
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", paddingTop: '0px', paddingBottom: '0px' }}>
                        <td style={{ fontSize: '8px', textAlign: 'center', marginTop: '-5px' }}>
                            {extraInfo && extraInfo.quotationItemStepPrint &&
                                <h6>{getDate(extraInfo.quotationItemStepPrint)}</h6>
                            }
                        </td>
                    </tr>
                </td>
                <td className="col-lg-4">
                    {extraInfo.customerInfo && extraInfo.customerInfo.logoUrl &&
                        <img src={extraInfo.customerInfo.logoUrl} className="logo-header-agency" />
                    }
                </td>
            </tr>
            <tr>
                <td style={{ color: "#08123B", minHeight: '25px' }}>
                </td>
            </tr>
        </table>
    </>
    )
}
