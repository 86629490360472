import React from 'react';
import Cookies from 'universal-cookie';
import { Modal } from '../../Common/Modal.js';
import { formatPrice } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const CancelReservationWithRefundModal = ({ pnr, productType }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    async function handleOnConfirm() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType, cancellationType: 1 })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/CancelReservation', requestOption);
        const response = await fetchedRes.json();

        let outObj = {
            success: response.success,
            bodyHtml: response.success
                ? "<p><span class='penalty free'>Cancellazione avvenuta con successo</span></p>"
                : "<p><span class='penalty'>Si &egrave; verificato un errore durante la cancellazione.</span></p>"
        };

        return outObj;
    }

    async function handleOnOpen() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType, cancellationType: 1 })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetCancellationCost', requestOption);
        const response = await fetchedRes.json();

        let outObj = {
            success: response.success,
            bodyHtml: ""
        };

        if (!response.success) {
            if (response.code) {
                switch (response.code) {
                    case "[RESTRICTION]":
                        outObj.bodyHtml = "<p><span class='penalty'>Nell'ordine sono presenti prodotti con restrizioni (es. Package) e non è quindi possibile procedere con la cancellazione.</span></p>";
                        break;

                    default:
                        break;
                }
            } else {
                outObj.bodyHtml = "<p><span class='penalty'>Si &egrave; verificato un errore durante il recupero dei costi di cancellazione.</span></p>";
            }
        }
        else {
            let cancellationCost = response.cancellationCost;
            let penatyAmount = formatPrice(cancellationCost.amount, cancellationCost.currency, cultureName);

            outObj.bodyHtml = "<span>Prodotto: <label>" + cancellationCost.productName + "</label></span>";


            // se è un rimborso vale per i treni
            if (cancellationCost.type && cancellationCost.type === 'R') {
                outObj.bodyHtml += cancellationCost.isCancellationFree
                    ? "<p><span class='penalty'>Non e' previsto nessun rimborso</span></p>"
                    : "<p><span class='penalty free'>E' previsto un rimborso di: " + penatyAmount + "</span></p>";
            } else {
                outObj.bodyHtml += cancellationCost.isCancellationFree
                    ? "<p><span class='penalty free'>Cancellazione gratuita</span></p>"
                    : "<p><span class='penalty'>Cancellazione con penale: " + penatyAmount + "</span></p>";
            }

            outObj.bodyHtml += "<p><span>Clicca su 'Conferma Cancellazione' per procedere con la cancellazione.<span></p>";
        }

        return outObj;
    }

    const handleOnClose = () => {
        window.location.reload(true);
    }

    const modalSettings = {
        id: 'CancelReservationWithRefund_' + pnr,
        title: 'Cancellazione con Rimborso Prenotazione N. ' + pnr,
        body: null,
        lblClose: 'Chiudi',
        lblConfirm: 'Conferma Cancellazione',
        isLoading: true,
        loadingMsg: 'Caricamento in corso...',
        showBtnConfirm: true,
        showBtnClose: true,
        fnOnConfirm: handleOnConfirm,
        fnOnOpen: handleOnOpen,
        fnOnClose: handleOnClose
    };

    return (
        <Modal {...modalSettings} />
    );
}
