import React from 'react';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility';

export const QuotationPrintScadenziario = (props) => {
    function getTotalAmountRate(rataArr) {
        let total = 0;
        let currency = "";
        
        rataArr.map(el => {
            total += el.amount;
            currency = el.currency;
        });

        return formatPrice(total, currency, props.cultureName);
    }

    return (
        <>
            {props.scadenziario && props.scadenziario.length > 0 &&
                <div style={{ display: 'grid' }}>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                                <td className="borderThead">
                                    <h6>Descrizione</h6>
                                </td>
                                <td className="borderThead" style={{ width: '30%', maxWidth: '30%', }}>
                                    <h6>Data</h6>
                                </td>
                                <td className="borderThead" style={{ width: '30%', maxWidth: '30%', }}>
                                    <h6>Totale</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.scadenziario.map((rata, index) =>
                                <tr key={index} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }}>
                                    <td className="borderTBody" style={{ textAlign: 'left' }}>
                                        <h6>Rata {index+1}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ textAlign: 'left', width: '30%', maxWidth: '30%', }}>
                                        <h6>{rata[0]}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ textAlign: 'right', width: '30%', maxWidth: '30%', }}>
                                        <h6>{getTotalAmountRate(rata[1])}</h6>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}