import React from 'react';
import { getIconUrlToPrintCustom } from "../../../../js/PrintUtility";
import { getCDNLogoDocument } from "../../../../js/CDNUtility";

export const BodyFirstPagePDFPrint = ({ extraInfo }) => {
    return (<>
        <table>
            <tr className="badge-customer" style={{ textAlign: 'left', paddingTop: '5px', paddingBottom: '5px' }}>
                <td className="col-lg-7 text-white" style={{ textAlign: 'left', margin: '5px', marginLeft: '20px', }}>
                    <h4>
                        {extraInfo.titleFirstPage}
                    </h4>
                </td>
                <td className="col-lg-3 text-white" style={{ textAlign: 'left' }}>
                    <div className="badge-white" style={{ color: 'black', top: '7px', left: '9px' }}>
                        <h5>
                            N. {extraInfo.quotationId}
                        </h5>
                    </div>
                </td>
            </tr>
        </table>
        <img src={getIconUrlToPrintCustom('copertina')} className="copertina-img" />
    </>
    )
}
