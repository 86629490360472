import React from 'react';

export const PrintVoucherFooter = ({ }) => {
  
    return (<>
        <table>
            <tr className="row" style={{ borderTop: '1px solid #d0d1d6', marginTop: '10px', }}>
                <td className="col-lg-12">
                    <span style={{ textAlign: 'center' }}>
                        Going S.r.l.
                        Piazzale Lorenzo Lotto n.2 - Milano 
                        Cap. soc. 100.000 € - P.IVA: 05197460966 - R.E.A. 1803899 - MI
                        Società soggetta a direzione e coordinamento di Mediterranean Shipping Company SA
                    </span>
                </td>
            </tr>
            <tr className="row">
                <td className="col-lg-12">
                    <span></span>
                </td>
            </tr>
        </table>
    </>);
}