import React from 'react';
import Cookies from 'universal-cookie';

export const ConfirmPrintProductsNotes = (props) => {

    return (
        <>
            <h2 style={{ marginLeft: '0px', marginRight: '20px' }}>{props.name}</h2>
            <span style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: props.note }} />
        </>
    );
}