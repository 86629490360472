import React from 'react';
import { StructureDetailPrint } from '../../Reservation/Print/StructureDetailPrint';
import { FlightDetailPrint } from '../../Reservation/Print/FlightDetailPrint';
import { ActivityDetailPrint } from '../../Reservation/Print/ActivityDetailPrint';
import { CarRentalDetailPrint } from '../../Reservation/Print/CarRentalDetailPrint';
import { TransferDetailPrint } from '../../Reservation/Print/TransferDetailPrint';
import { CruiseDetailPrint } from '../../Reservation/Print/CruiseDetailPrint';
import { TourDetailPrint } from '../../Reservation/Print/TourDetailPrint';
import { ConfirmPrintImageDynamicNotes } from "../printConfirm/ConfirmPrintImageDynamicNotes";
import { TrainDetail } from '../../Reservation/TrainDetail';


export const ConfirmPrintStepItem = ({ qInfo, isPrintCliente, isNotesHide, dynamicNotesDistinct }) => {
    let oldIdFoundImage = localStorage.getItem("oldIdFoundImage");

    /* Immagine nota */
    var idFound = null;
    var notaImg = null;
    
    if (qInfo.productInfo.webDestinationIds) {
        for (var i = 0; i < dynamicNotesDistinct.length; i++) {
            var arrDestIds = dynamicNotesDistinct[i].webDestinationsIds;
            for (var j = 0; j < qInfo.productInfo.webDestinationIds.length; j++) {
                idFound = arrDestIds.find(el => el == qInfo.productInfo.webDestinationIds[j]);
                if (idFound && idFound !== oldIdFoundImage) {
                    localStorage.setItem("oldIdFoundImage", idFound)
                    notaImg = dynamicNotesDistinct[i].urlBgImageB;
                    break;
                }
            }
        }
    }
    
    return (
        <div className={"row" + (qInfo.isDisabled ? " quotation-opacity-3" : "")}>
            {notaImg &&
                <ConfirmPrintImageDynamicNotes
                    notaImg={notaImg}
                />
            }
            {
                qInfo.productType === 'Structure' && <StructureDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    structureDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isNotesHide={isNotesHide}
                    isConfirmPrint={true }
                />
            }
            {
                qInfo.productType === 'Flight' && <FlightDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    flightDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isConfirmPrint={true}
                />
            }
            {
                qInfo.productType === 'Activity' && <ActivityDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    activityDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    productSubType={qInfo.productSubType}
                    isNotesHide={isNotesHide}
                    isConfirmPrint={true}
                />
            }
            {
                qInfo.productType === 'Transfer' && <TransferDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    transferDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isConfirmPrint={true}
                    isNotesHide={isNotesHide }
                />
            }
            {
                qInfo.productType === 'CarRental' && <CarRentalDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    carRentalDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isConfirmPrint={true}
                    isNotesHide={isNotesHide}
                />
            }
            {
                qInfo.productType === 'Cruise' && <CruiseDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    cruiseDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isNotesHide={isNotesHide}
                    isConfirmPrint={true}
                />
            }
            {
                qInfo.productType === 'Tour' && <TourDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    tourDetail={qInfo.productInfo}
                    isPrintCliente={isPrintCliente}
                    isNotesHide={isNotesHide}
                    isConfirmPrint={true}
                />
            }
            {/*
                qInfo.productType === 'Train' && <TrainDetailPrint
                    key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                    type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                    showNetPrices={false}
                    trainDetail={qInfo.productInfo }
                    isPrintCliente={isPrintCliente}
                    />
            */}
        </div>
    );
}