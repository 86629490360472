import React from 'react';
import Cookies from 'universal-cookie';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility';

export const ConfirmPrintAncillaries = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // verifica se esite qualche valore
    function checkIfExistValues(recapItems) {
        let ok = recapItems.filter((item) => item.productType === "Generic")
        if (ok.length > 0)
            return true;
        else
            return false;
    }

    return (
        <>
            {props.recapItems && checkIfExistValues(props.recapItems) ?
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0">QUOTE ACCESSORIE</h6>
                    </div>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                                <td className="borderThead">
                                    <h6>Descrizione</h6>
                                </td>
                                {!props.isPrintCliente &&
                                    <td className="borderThead" style={{ width: '30%', maxWidth: '30%', }}>
                                        <h6>Quota in €</h6>
                                    </td>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.recapItems &&
                                props.recapItems.filter((item) => item.productType === "Generic" && item.IdTipoProdotto != "3" && item.IdTipoProdotto != "6")
                                    .map((item, key) => 
                                    <>
                                        <tr key={key} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                            <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                <h6>{item.productInfo.name}</h6>
                                            </td>
                                            {!props.isPrintCliente &&
                                                <td className="borderTBody" style={{ width: '30%', maxWidth: '30%', textAlign: 'right' }}>
                                                    <h6>{formatPrice(item.productInfo.priceBar.invoicePrice, item.productInfo.priceBar.currency, cultureName)}</h6>
                                                </td>
                                            }
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            : <></>
            }
        </>
    );
}