import React, { useState, useEffect, useCallback } from 'react';
import { M3Icon } from '../../Common/M3Icon';
import { useTranslation } from 'react-i18next'
import { handleError, GetModulesRules } from "../../../js/Utils";
import { WebMenuVoices } from './WebMenuVoices';
import configData from "./../../../appsettings.json";
import { getCDNLogoDocument } from "../../../js/CDNUtility";
import Cookies from 'universal-cookie';

export const WebVerticalMenu = ({ item, isActive, handleModal }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");

    const { t } = useTranslation();

    const [myWebMenuVertical, setMyWebMenuVertical] = useState(null);
    const [myKeyWebMenuVertical, setMyKeyWebMenuVertical] = useState(null);
    const [rules, setRules] = useState(null);

    useEffect(() => {

        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };

        callGetModuleRules();
        GetWebMenuVerticalVoices();

    }, []);

    useEffect(() => {
        if (cultureCode) {
            setMyKeyWebMenuVertical("WebMenuVericalItems_" + cultureCode);
        }
    }, [cultureCode]);

    useEffect(() => {
        if (myKeyWebMenuVertical) {
            GetWebMenuVerticalVoices();
        }
    }, [myKeyWebMenuVertical]);


    function GetWebMenuVerticalVoices() {
        let strMyHeader = sessionStorage.getItem(myKeyWebMenuVertical);
        if (!strMyHeader) {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let cc = cultureCode;
            if (!cc)
                cc = 'it-IT';

            fetch(`${configData.Settings.CommonApi_BaseUrl}website/getwebsitemenu/2/${configData.Settings.WebsiteId}/${cc}`, requestOption)
                .then((res) => handleError(res))
                .then((item) => {
                    const headerItems = { items: item };
                    setMyWebMenuVertical(headerItems);
                    sessionStorage.setItem(myKeyWebMenuVertical, JSON.stringify(headerItems));
                })
                .catch((err) => {
                    console.error(err);
                });

        } else {
            let jCurrentHeader = JSON.parse(strMyHeader);
            setMyWebMenuVertical(jCurrentHeader);
        }
    }

    const handleInputClick = useCallback(event => {
        handleModal("");
    }, [handleModal]);

    return (
        <nav id="menu-vertical" className={"nav flex-column flex-nowrap vertical-menu open-left shadow-sm bg-white p-3 " + isActive}>
            <div class="d-flex justify-content-between align-items-center pb-2 border-bottom">
                <h5 className="fw-bold mb-0">Menu</h5>
                <div class="cursor-pointer pl-2" onClick={e => handleInputClick(e)}>
                    <M3Icon iconName="Close" />
                </div>
            </div>
            <div class="d-flex flex-column h-100">
                {
                    myWebMenuVertical && myWebMenuVertical.items.map((item, key) => {
                        return <WebMenuVoices key={key} item={item} rules={rules} />
                    })
                }
            </div>
        </nav>
    );

}
