import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { createRenderHtml } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements } from '../../js/PrintUtility';


export const DynamicNote = ({ pageId, idTipoProdotto, productDetail, customDynamicNote }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState([]);

    const { t } = useTranslation();


    useEffect(() => {
        const getDynamicNote = async (inputData) => {
            const response = callGetNotes(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: configData.Settings.WebsiteId,
            pageId: pageId,
            styleId: 4,
            productTypes: [idTipoProdotto],
            productCode: productDetail.productId,
            checkInFrom: productDetail.priceBar ? productDetail.priceBar.startDate : null,
            checkInTo: productDetail.priceBar ? productDetail.priceBar.endDate : null,
            webDestinationIds: productDetail.webDestinationIds ? productDetail.webDestinationIds : null,
            supplierId: productDetail.supplierId ? productDetail.supplierId : null
        });

        setIsLoading(true);
    }, []);

    async function callGetNotes(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();
        
        setNotes(response.dynamicNotes);
        customDynamicNote(response.dynamicNotes);
        setIsLoading(false);
    }
    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (notes && notes.length > 0 &&
                    <>
                        <div className="stepHeader text-center color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">Note &nbsp;{t("home:title")}</h5>
                        </div>
                        {notes && notes.map((n, index) => 
                            <>
                                <div style={{ padding: "7px" }}></div>
                                <div style={{ border: "1px solid grey", borderRadius: "5px", padding: "5px", textAlign: "justify" }}>
                                    <span style={{ textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(n.note) }} />
                                </div>
                            </>
                        )}
                    </>
                )
            }
        </>
    );
}
