import React from 'react';
import { formatPrice } from '../../../js/Utils.js';
import { translateQuotationCommissionableGroupType } from '../enums/quotationTOEnums';

export const QuotationPrintCommissionsDetail = (props) => {
    function getNettoPagare(notCommissionableItems, commissionableItems) {
        let total = 0;
        let currency = 0;

        notCommissionableItems.forEach(function (item) {
            currency = item.grossPriceCurrency;
            total = total + item.grossPriceAmount;
        });

        commissionableItems.forEach(function (item) {
            currency = item.grossPriceCurrency;

            if (item.type === 'TotaleAgencyFee' || item.type === 'TotaleAgencyMarkup') {
                total = total + item.totalCommissioneAmount;
            } else {
                total = total + (item.grossPriceAmount - item.totalCommissioneAmount);
            }

        });

        return formatPrice(total, currency, props.cultureName);
    }

    function getIsCommissionable() {
        let isCommissionable = false;
        if (props.marginItems.tableMargin.commissionableItems && props.marginItems.tableMargin.commissionableItems.length > 0) {
            isCommissionable = true;
        }

        return isCommissionable;
    }

    return (
        <>
            {props.marginItems.tableMargin && !props.isPrintCliente ?
                <div style={{ display: 'grid' }}>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }}>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h4>{props.totaleViaggio}</h4>
                                </td>
                                <td className="borderThead">
                                    <h4>{getIsCommissionable() ? "Commissionabile" : "Netto"}</h4>
                                </td>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h4>{getNettoPagare(props.marginItems.tableMargin.notCommissionableItems, props.marginItems.tableMargin.commissionableItems)}</h4>
                                </td>
                            </tr>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }}>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h6>Totale Viaggio</h6>
                                </td>
                                <td className="borderThead">
                                    <h6>Dettaglio Commissioni</h6>
                                </td>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h6>Netto da Pagare</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.marginItems.tableMargin.notCommissionableItems && props.marginItems.tableMargin.notCommissionableItems.length > 0 &&
                                props.marginItems.tableMargin.notCommissionableItems.map((item, index) =>
                                    <>
                                        <tr key={index} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'left' }}>
                                                <h6>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</h6>
                                            </td>
                                            <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                <h6>{translateQuotationCommissionableGroupType(item)}</h6>
                                            </td>
                                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'right' }}>
                                                <h6>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</h6>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                            {props.marginItems.tableMargin.commissionableItems && props.marginItems.tableMargin.commissionableItems.length > 0 &&
                                props.marginItems.tableMargin.commissionableItems.map((item, index) =>
                                    <>
                                        <tr key={index} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }}>
                                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'left' }}>
                                                <h6>
                                                    {
                                                        (item.type === 'TotaleAgencyFee' || item.type === 'TotaleAgencyMarkup')
                                                            ? <>{formatPrice(item.totalCommissioneAmount, item.grossPriceCurrency, props.cultureName)}</>
                                                            : <>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</>
                                                    }
                                                </h6>
                                            </td>
                                            <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                <h6>
                                                    {translateQuotationCommissionableGroupType(item)}
                                                    {(item.type !== 'TotaleAgencyFee' && item.type !== 'TotaleAgencyMarkup') && <>({formatPrice(item.totalCommissioneAmount, item.totalCommissioneCurrency, props.cultureName)})</>}
                                                </h6>
                                            </td>
                                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%', textAlign: 'right' }}>
                                                <h6>
                                                    {
                                                        (item.type === 'TotaleAgencyFee' || item.type === 'TotaleAgencyMarkup')
                                                            ? <>{formatPrice(item.totalCommissioneAmount, item.totalCommissioneCurrency, props.cultureName)}</>
                                                            : <>
                                                                {formatPrice((item.grossPriceAmount - item.totalCommissioneAmount), item.totalCommissioneCurrency, props.cultureName)}
                                                                {
                                                                    parseFloat(item.totalIVACee) > 0 ?
                                                                        <>
                                                                            (* {item.totalIVACee})
                                                                        </>
                                                                        : ""
                                                                }
                                                            </>
                                                    }
                                                </h6>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                : <></>
            }
        </>
    );
}