import React from 'react';

export const QuotationTourStaticPrint = (props) => {

    return (
        <>
            {props.tourName && <h2 style={{ marginLeft: '0px', marginRight: '20px', textAlign: "left", textDecoration: 'underline' }}>{props.tourName}</h2>}
            <h4 style={{ marginLeft: '0px', marginRight: '20px', paddingBottom: '10px', textAlign: "left" }}>{props.title}</h4>
            <span style={{ marginLeft: '20px', marginRight: '20px', textAlign: 'justify', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: props.descriptions }} />
        </>
    );
}