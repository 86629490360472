import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { StructureBoxStatic } from '../product/BoxStatic/StructureBoxStatic';
import { HotelModalStatic } from '../../Product/Hotel/components/HotelModalStatic';
import { ActivityBoxStatic } from '../product/BoxStatic/ActivityBoxStatic';
import { ActivityModalStatic } from '../../Product/Activity/components/ActivityModalStatic';
import { TourBoxStatic } from '../product/BoxStatic/TourBoxStatic';
import { TourModalStatic } from '../../Product/Tour/components/TourModalStatic';
import { TemplateInclusion } from '../TemplateUtils';

export const TemplateBoxStatic = ({ steps }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [modalOpenStructureId, setModalOpenStructureId] = useState(null);
    const [modalOpenActivityId, setModalOpenActivityId] = useState(null);
    const [modalOpenTourId, setModalOpenTourId] = useState(null);

    let aTemplateData = [];
    if (steps && steps.length > 0) {
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].templateData && steps[i].templateData.length > 0) {
                let step = steps[i];
                let selectedItemsOriginal = step.templateData.filter(x => x.inclusion !== TemplateInclusion.AtLeastOne || x.isDefault === true);
                let alternativeItems = step.templateData.filter(x => x.inclusion === TemplateInclusion.AtLeastOne && x.isDefault === false && x.productType !== 5);

                aTemplateData.push({
                    selectedItemsOriginal,
                    alternativeItems
                });

                //for (let j = 0; j < steps[i].templateData.length; j++) {
                //    if (steps[i].templateData[j].inclusion === TemplateInclusion.Mandatory || steps[i].templateData[j].isDefault)
                //        aTemplateData.push(steps[i].templateData[j]);
                //}
            }
        }
    }

    return (
        <>
            {aTemplateData && aTemplateData.length > 0
                ? (
                    <>
                        <h3>{t('Template:ServicesDescription')}</h3>
                        {
                            aTemplateData.map((tItems, idx) => {
                                return (
                                    <React.Fragment key={idx}>

                                        {
                                            tItems.selectedItemsOriginal && tItems.selectedItemsOriginal.length > 0 && tItems.selectedItemsOriginal.map((tData, key) => {
                                                return <React.Fragment key={key}>
                                                    {tData.productType === 0 && <StructureBoxStatic structure={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenStructureId={setModalOpenStructureId} />}
                                                    {tData.productType === 1 && <ActivityBoxStatic activity={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenActivityId={setModalOpenActivityId} />}
                                                    {tData.productType === 10 && <TourBoxStatic tour={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenTourId={setModalOpenTourId} />}
                                                </React.Fragment>
                                            })
                                        }
                                        {
                                            tItems.alternativeItems && tItems.alternativeItems.length > 0 && <>
                                                <div className="accordion" id={"accordion" + idx}>
                                                    <div className="accordion-item border border-dark rounded mb-2">
                                                        <h2 className="accordion-header" id={"heading_" + idx}>
                                                            <button className="accordion-button collapsed bg-gray text-dark"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#collapseOne_" + idx}
                                                                aria-expanded="true"
                                                                aria-controls={"collapseOne_" + idx}>
                                                                {t(`Template:AlternativeProducts`)}
                                                            </button>
                                                        </h2>
                                                        <div id={"collapseOne_" + idx}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={"heading_" + idx}
                                                            data-bs-parent={"#accordion" + idx}>
                                                            <div className="accordion-body">
                                                                {
                                                                    tItems.alternativeItems.map((tData, key) => {
                                                                        return <React.Fragment key={key}>
                                                                            {tData.productType === 0 && <StructureBoxStatic structure={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenStructureId={setModalOpenStructureId} />}
                                                                            {tData.productType === 1 && <ActivityBoxStatic activity={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenActivityId={setModalOpenActivityId} />}
                                                                            {tData.productType === 10 && <TourBoxStatic tour={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenTourId={setModalOpenTourId} />}
                                                                        </React.Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                )
                : (<></>)
            }

            {modalOpenStructureId && <HotelModalStatic structureId={modalOpenStructureId} modalCloseProductId={setModalOpenStructureId} isNotEngine={true} />}
            {modalOpenActivityId && <ActivityModalStatic activityId={modalOpenActivityId} modalCloseProductId={setModalOpenActivityId} isNotEngine={true} />}
            {modalOpenTourId && <TourModalStatic tourId={modalOpenTourId} modalCloseProductId={setModalOpenTourId} isNotEngine={true} />}
        </>
    );
}
