import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility';

export const ConfirmPrintDiscount = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // verifica se esite qualche valore
    function checkIfExistValues(recapItems) {
        let ok = recapItems.filter((item) => item.productType === "Generic" && item.IdTipoProdotto === "3" && item.IdTipoProdotto === "6")
        if (ok.length > 0)
            return true;
        else
            return false;
    }
    
    return (
        <>
            {props.recapItems && checkIfExistValues(props.recapItems) ?
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 style={{ color: '#000000' }}>SCONTO COMMERICALE</h6>
                    </div>
                    <table>
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }}>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%' }}>
                                    <h6>Immagine</h6>
                                </td>
                                <td className="borderThead">
                                    <h6>Descrizione</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.recapItems &&
                                props.recapItems.filter((item) => item.productType === "Generic" && item.IdTipoProdotto === "3" && item.IdTipoProdotto === "6")
                                    .map((item, key) => 
                                    <>
                                        <tr key={key} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                            <td className="borderTBody" style={{ width: '20%', maxWidth: '20%' }}>
                                                <img src={`data:image;base64, `} alt="Thumb" className="card-img" onError={imageOnError} />
                                            </td>
                                            <td className="borderTBody" style={{ textAlign: 'left' }}>
                                                <h6>{item.productInfo.name}</h6>
                                            </td>
                                        </tr>
                                        {/*note*/ }
                                        <tr style = {{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', padding: '5px' }}>
                                            <td style={{ textAlign: 'left' }} >
                                                <h5>Note aggiuntive</h5>
                                                    {item.productInfo.notes ?
                                                    <tr style={{ textAlign: 'left', marginTop: '5px' }}>
                                                        <span>{item.productInfo.notes}</span>
                                                    </tr>
                                                : <></>
                                                }
                                            </td>
                                        </tr>
                                        {/*totale costo*/}
                                        <tr className="badge-customer-yellow" style={{ padding: '8px',}}>
                                            <td style={{ textAlign: 'left', paddingTop: '0px', paddingBottom: '0px' }}>
                                            </td>
                                            <td className="col-lg-3" style={{ textAlign: 'right', backgroundColor: '#FFFFFF', paddingTop: '0px', paddingBottom: '0px' }}>
                                                <h4>{formatPrice(item.productInfo.priceBar.invoicePrice, item.productInfo.priceBar.currency, cultureName)}</h4>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            : <></>
            }
        </>
    );
}