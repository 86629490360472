import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getStructureListLink, generateClientFlowId } from '../../js/ProductService.js';
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';

export const RedirectProduct = () => {
    const [searchParams] = useSearchParams();
    const hspParam = searchParams.get('hsp'); 

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);



    useEffect(() => {

        try {
            if (hspParam) {
                const searchHotel = async () => {
                    let decodeSP = decodeURIComponent(hspParam);
                    let newSP = JSON.parse(decodeSP);

                    // search by destination
                    let clientFlowId = generateClientFlowId();
                    let url = await getStructureListLink(newSP, clientFlowId, false);
                    window.open(url, '_self');
                }
                searchHotel();
                
            }
        } 
        catch (ex) {
            setIsError(true);
            setIsLoading(false);
        }

    }, [])

    return (
        <>
            {
                isLoading && <Loading textMsg="Stai per essere renderizzato alla pagina di ricerca" />
            }
            {
                isError && <Error textMsg="si è verificato un errore" />
            }
        </>
    );
}