import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { PassengerDetailPrint } from './PassengerDetailPrint';
import { replaceAllStyleElements } from '../../../js/PrintUtility';

export const TransferDetailPrint = ({ id, type, showNetPrices, transferDetail, isPrintCliente, isConfirmPrint = false }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {transferDetail &&
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0 text-white">TRASFERIMENTO</h6>
                    </div>
                    <table>
                        <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                            <td className="borderThead" style={{ width: '18%', maxWidth: '18%' }}>
                                <h6>Immagine</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Descrizione Servizio</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Località Partenza</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Località Arrivo</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Data</h6>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                            <td className="borderTBody" style={{ width: '18%', maxWidth: '18%' }}>
                                <img src={`data:image;base64, ` + transferDetail.thumbUrlB} alt="Thumb" className="card-img" onError={imageOnError} />
                            </td>
                            <td className="borderTBody">
                                <h6>{transferDetail.name}</h6>
                            </td>
                            <td className="borderTBody">
                                <h6>{transferDetail.outbound.from}</h6>
                            </td>
                            <td className="borderTBody">
                                <h6>{transferDetail.outbound.to}</h6>
                            </td>
                            <td className="borderTBody">
                                <h6>{formatDateTime(transferDetail.outbound.date, cultureName)}</h6>
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*note*/}
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: (isPrintCliente ? '1px dotted grey' : 'none'), padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Note aggiuntive</h5>
                                {transferDetail.quotationDetail != null && transferDetail.quotationDetail.isManualLoading && transferDetail.notes ?
                                    <div> {/* da lascaire per problema split pagina react PDF */}
                                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(transferDetail.notes) }} /></p>
                                    </div>
                                    : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*totale costo*/}
                        {!isPrintCliente &&
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {isConfirmPrint &&
                                        <h5>{t(`statiPrenotazione:` + transferDetail.priceBar.status)}</h5>
                                    }
                                    <label>{transferDetail.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(transferDetail.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(transferDetail.priceBar.invoicePrice, transferDetail.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        }
                    </table>
                    <table>
                        {/*passeggeri*/}
                        {
                            <PassengerDetailPrint
                                passengers={transferDetail.passengers}
                            />
                        }
                    </table>
                </div>
            }
        </>
    );
}