import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility';

export const QuotationPrintTitleQuotation = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {props.title ?
                <table style={{ marginTop: '-30px', marginBottom: '20px' }}>
                    <tr>
                        <td>
                        </td>
                        <td style={{ textAlign: 'left', borderLeft: '1px dotted grey', borderBottom: '1px dotted grey' }}>
                            <h5>N. Preventivo: </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderRight: '1px dotted grey', borderBottom: '1px dotted grey' }}>
                            <h5>{props.quotationId && props.quotationId} </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderBottom: '1px dotted grey' }}>
                            <h5>Data: </h5>
                        </td>
                        <td style={{ textAlign: 'left', borderBottom: '1px dotted grey', borderRight: '1px dotted grey' }}>
                            <h5>{props.date && formatDateTime(props.date, props.cultureName)} </h5>
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
            : <></>
            }
        </>
    );
}