import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationOnlyRead } from '../../Common/Destination/InputSearchDestinationOnlyRead';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputSearchHotel } from '../../Common/Destination/InputSearchHotel';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { SelectRay } from '../../Common/SelectRay';
import { getStructureDetailLink, getStructureListLink, generateClientFlowId, getStructureCarpetId, callGetLastItemsSearch, callGetGetProductRules } from '../../../js/ProductService.js';
import { getAuthToken, getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const StructureSearchEngine = ({ searchParameters, typeSearch, extraParams, modeView, resetValues, isFake, isFromMap, isTrippie, onSearchTrippie }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [enableSearchHotel, setEnableSearchHotel] = useState(true);

    const [internalSearchType, setInternalSearchType] = useState(1);

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [hotels, setHotels] = useState([]);

    const [textColorClass, setTextColorClass] = useState('text-white');

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestination, setSelectDestination] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    const [excludePackage, setExcludePackage] = useState(false);
    const [excludeWithPenalty, setExcludeWithPenalty] = useState(false);

    const [canGenerateUrl, setCanGenerateUrl] = useState(false);
    const [generateUrl, setGenerateUrl] = useState('');

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState(false);
    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showSearchHotel, setShowSearchHotel] = useState(false);

    // gestione raggio
    const [showRay, setShowRay] = useState(false);
    const [selectRay, setSelectRay] = useState(1);


    useEffect(() => {
        if (!isFake) {
            const getLastSearchItems = async () => {
                const response = await callGetLastItemsSearch(1);
                setLastSearchItems(response);
            }
            getLastSearchItems();

            const getRules = async () => {
                const response = await callGetGetProductRules(1);

                if (response) {
                    try {
                        setIsValidComposition(response['ADDTOCART'].allowed);
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            }
            getRules();
        }

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');

        let token = getAuthToken();
        if (token && token !== "") {
            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && currentUser.roles.includes("W_NoSearchHotel"))
                setEnableSearchHotel(false);

            if (currentUser && currentUser.roles.includes("FE_GeneratoreUrl"))
                setCanGenerateUrl(!typeSearch || typeSearch === 'SRC');
        } else {
            setEnableSearchHotel(false);
        }
    }, []);

    useEffect(() => {
        setShowDestination(false);
        setShowPaxes(false);
        setShowCalendar(false);
        setShowSearchHotel(false);

        setValuesHowDefault();
    }, [resetValues]);

    useEffect(() => {
        setValuesHowDefault();

        if (searchParameters) {
            // TODO prendere dai searchParam quando sarà implementata la funzione
            //setExcludeWithPenalty(searchParameters.excludeWithPenalty);
            //setExcludePackage(searchParameters.excludePackage);
        } else {
            setExcludeWithPenalty(false);
            setExcludePackage(false);
        }

    }, [searchParameters]);


    function setValuesHowDefault() {

        if (searchParameters) {
            let updateDestination = {
                Text: searchParameters.destinationDesc,
                Id: searchParameters.destinationWebId,
                Type: searchParameters.destinationType,
                Ray: searchParameters.ray,
                Longitude: searchParameters.longitude,
                Latitude: searchParameters.latitude
            };
            setSelectDestination(updateDestination);
            setShowRay(updateDestination.Type !== 'Destination');

            if (searchParameters.ray && updateDestination.Type !== 'Destination')
                setSelectRay(searchParameters.ray);

            let updateTravelers = searchParameters.roomsComposition;
            setSelectComposition(updateTravelers);

            let updateCalendar = {
                dateFrom: searchParameters.checkIn,
                dateTo: searchParameters.checkOut,
            };
            setSelectDates(updateCalendar);

            checkIsValidForm(updateDestination, updateTravelers, updateCalendar);

            setDefaultCitizenshipId(searchParameters.citizenship);

        } else {
            checkIsValidForm(null, null, null);
        }
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {

            let newSP = createSP();

            let onRequest = false;
            let testRequest = false;
            if (extraParams && extraParams.testRequest)
                testRequest = true;

            if (extraParams && extraParams.onRequest)
                onRequest = true;

            if (isTrippie) {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getStructureCarpetId(newSP, clientFlowId);

                onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.Structure.IdTipoProdotto);
                setIsStartSearch(false);
                return;
            }

            let extraParamUrl = {
                testRequest: testRequest,
                onRequest: onRequest
            };

            if (typeSearch === 'SRC') {
                if (internalSearchType === 1 || !hotels || hotels.length === 0) {
                    // search by destination
                    let clientFlowId = generateClientFlowId();
                    let url = await getStructureListLink(newSP, clientFlowId, isFromMap);
                    window.open(url, '_self');
                } else {
                    if (hotels && hotels.length === 1) {
                        // go to detail
                        newSP.structureId = hotels[0].structureId;

                        let clientFlowId = generateClientFlowId();
                        let url = await getStructureDetailLink(newSP, hotels[0].structureId, null, clientFlowId, true, extraParamUrl);
                        window.open(url, '_self');
                    } else {
                        // search by ids
                        let structureIds = [];
                        for (let i = 0; i < hotels.length; i++) {
                            structureIds.push(hotels[i].structureId);
                        }

                        newSP.destinationType = "SearchByIds";
                        newSP.structureIds = structureIds;

                        let clientFlowId = generateClientFlowId();
                        let url = await getStructureListLink(newSP, clientFlowId, isFromMap);
                        window.open(url, '_self');
                    }
                }
            }

            if (typeSearch === 'AVL') {
                if (searchParameters) {
                    // ricerca sul dettaglio

                    let cId = null;
                    if (extraParams)
                        cId = extraParams.carpetId;

                    let cfId = '';
                    if (!extraParams || !extraParams.clientFlowId)
                        cfId = generateClientFlowId();
                    else
                        cfId = extraParams.clientFlowId;

                    let url = await getStructureDetailLink(newSP, extraParams.structureId, cId, cfId, true, extraParamUrl);
                    window.open(url, '_self');
                }
                else {
                    // ricerca sul dettaglio statico
                    newSP.destinationDesc = extraParams.city;
                    newSP.structureId = extraParams.structureId;

                    let clientFlowId = generateClientFlowId();
                    let url = await getStructureDetailLink(newSP, extraParams.structureId, null, clientFlowId, true, extraParamUrl);
                    window.open(url, '_self');
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);
    }

    async function onGenerateUrl() {
        let myUrl = "";

        let sp = createSP();

        let jSP = JSON.stringify(sp);
        let encodeSP = encodeURIComponent(jSP);

        myUrl = `/redirectProduct?hsp=${encodeSP}`;

        setGenerateUrl(myUrl)
    }
    function copyText() {
        // Seleziona il campo di input
        const copyInput = document.getElementById('inputText');

        // Seleziona il testo
        copyInput.select();
        copyInput.setSelectionRange(0, 99999); // Per dispositivi mobili

        // Copia il testo selezionato
        document.execCommand('copy');
    }
    function createSP() {
        let newSP = {};
        let testRequest = false;
        let onRequest = false;
        if (extraParams && extraParams.testRequest)
            testRequest = true;

        if (extraParams && extraParams.onRequest)
            onRequest = true;

        if (searchParameters) {
            newSP = searchParameters;
            newSP.testRequest = testRequest;
        } else {
            newSP = {
                structureId: null,
                destinationDesc: null,
                destinationId: null,
                destinationWebId: null,
                destinationType: null,
                structureIds: null,
                latitude: 0,
                longitude: 0,
                ray: 10,
                checkIn: null,
                checkOut: null,
                roomsComposition: [],
                citizenship: 380,
                suppliersToExclude: null,
                refundableOnly: false,
                templateId: null,
                timeout: null,
                groupedByRoomName: false,
                testRequest: testRequest,
                agencyMarkup: null,
                agencyFee: null
            };
        }

        // TODO prendere dai searchParam quando sarà implementata la funzione
        //searchParameters.excludeWithPenalty = excludeWithPenalty;
        //searchParameters.excludePackage = excludePackage;

        if ((typeSearch === 'SRC' && selectDestination) || (typeSearch === 'AVL' && searchParameters)) {
            newSP.destinationDesc = selectDestination.Text;
            newSP.destinationWebId = selectDestination.Id;
            newSP.destinationType = selectDestination.Type;
        }

        newSP.checkIn = selectDates.dateFrom;
        newSP.checkOut = selectDates.dateTo;

        newSP.roomsComposition = selectComposition;

        if (selectCitizenship)
            newSP.citizenship = selectCitizenship.isoId;

        if (selectRay) {
            newSP.ray = selectRay;

            if (selectDestination) {
                newSP.Latitude = selectDestination.Latitude;
                newSP.Longitude = selectDestination.Longitude;
            }
        }

        return newSP;
    }

    function checkIsValidForm(destinationUpdate, travelersUpdate, calendarUpdate) {
        let isValidFormUpdate = {
            isValidDestination: typeSearch === 'AVL',
            isValidDates: false,
            isValidComposition: false,
            isAllFieldValid: false
        };

        if (typeSearch !== 'AVL')
            if (destinationUpdate) {
                if (destinationUpdate.Text && destinationUpdate.Id)
                    isValidFormUpdate.isValidDestination = true;
            } else {
                if (selectDestination && selectDestination.Text && selectDestination.Id)
                    isValidFormUpdate.isValidDestination = true;
            }


        if (travelersUpdate)
            isValidFormUpdate.isValidComposition = true;
        else if (selectComposition)
            isValidFormUpdate.isValidComposition = true;

        if (calendarUpdate && calendarUpdate.dateFrom && calendarUpdate.dateTo)
            isValidFormUpdate.isValidDates = true;
        else
            if (selectDates && selectDates.dateFrom && selectDates.dateTo)
                isValidFormUpdate.isValidDates = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && (isValidFormUpdate.isValidDestination || (hotels && hotels.length > 0));

        setIsValidForm(isValidFormUpdate);
    }

    // funzioni destinazione
    function onSelectSearchDestination(selectDestination) {
        setSelectDestination(selectDestination);
        setShowRay(selectDestination.Type !== 'Destination');
        checkIsValidForm(selectDestination, null, null);
    }

    function onOpenPanelDestination() {
        setShowCalendar(false);
        setShowPaxes(false);
        setShowDestination(true);
        setShowSearchHotel(false);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
        checkIsValidForm(null, selectComposition, null);
    }

    function onOpenPanelPaxes() {
        setShowDestination(false);
        setShowSearchHotel(false);
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowCalendar(true);
        setShowDestination(false);
        setShowSearchHotel(false);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        checkIsValidForm(null, null, values);
    }

    function getDestinationLabel() {
        if (extraParams && extraParams.productName)
            return { Text: extraParams.productName };

        return selectDestination;
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }

    // funzioni raggio
    function onSelectRay(item) {
        setSelectRay(item);
    }

    // find hotel
    function onChangeSearchType(event, type) {
        setInternalSearchType(type);
        //let isChecked = event.target.checked;
    }

    function onOpenPanelSearchHotel() {
        setShowCalendar(false);
        setShowPaxes(false);
        setShowDestination(false);
        setShowSearchHotel(true);
    }

    function onSelectHotel(item) {
        let tmpHotels = [];

        if (!hotels || hotels.length === 0) {
            tmpHotels.push(item);
        } else {
            for (let i = 0; i < hotels.length; i++) {
                tmpHotels.push(hotels[i]);
            }

            let hotelAlreadyExists = tmpHotels.filter(x => x.structureId === item.structureId).length > 0;
            if (!hotelAlreadyExists) {
                tmpHotels.push(item);
            }

        }

        setHotels(tmpHotels);
    }

    function onUnselectHotel(item) {
        let tmpHotels = [];

        for (let i = 0; i < hotels.length; i++) {
            let isMatchingHotel = hotels[i].structureId === item.structureId;
            if (!isMatchingHotel)
                tmpHotels.push(hotels[i]);
        }

        setHotels(tmpHotels);
    }


    return (
        <>
            <div className={`btn-group btn-sm rounded-1 ${!enableSearchHotel ? "d-none" : ""}`} role="group" aria-label="Basic example">
                <div className="form-check form-check-inline">
                    <input id="cbSearchDestination" className="form-check-input" type="radio" value="option1" checked={internalSearchType === 1} onChange={(e) => onChangeSearchType(e, 1)} />

                    <data m3ico="Destination text-gray-600 icon-10">
                        <M3Icon iconName="Destination" externalClass={"icon-10 " + textColorClass} />
                    </data>
                    <label className={"form-check-label " + textColorClass} htmlFor="cbSearchDestination">
                        <data m3lab="SearchEngine.Destination"> {t('SearchEngine:Destination')}</data>
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input id="cbFindHotel" className="form-check-input" type="radio" value="option2" checked={internalSearchType === 2} onChange={(e) => onChangeSearchType(e, 2)} />

                    <data m3ico="Structure text-gray-600 icon-10">
                        <M3Icon iconName="Structure" externalClass={"icon-10 " + textColorClass} />
                    </data>
                    <label className={"form-check-label  " + textColorClass} htmlFor="cbFindHotel">
                        <data m3lab="SearchEngine.FindHotel">{t('SearchEngine:FindHotel')}</data>
                    </label>
                </div>
            </div>

            <div className="row mb-3" style={{ pointerEvents: isFake && 'none' }}>
                {
                    <div className={!showRay && internalSearchType === 1 ? "col-12 col-sm-4 my-1" : "col-12 col-sm-3 my-1"}>
                        {
                            typeSearch === 'AVL' && <InputSearchDestinationOnlyRead
                                defaultSelectDestination={getDestinationLabel()}
                                onlyView={true} />
                        }
                        {
                            typeSearch !== 'AVL' && <InputSearchDestinationContainer
                                defaultSelectDestination={selectDestination}
                                productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                lastSearchItems={lastSearchItems}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination}
                                modeView={modeView} />
                        }
                    </div>
                }
                {
                    showRay && <div className="col-12 col-sm-2 my-1">
                        <SelectRay defaultRay={selectRay} onSelectRay={onSelectRay} />
                    </div>
                }

                {
                    internalSearchType === 2 && <div className="col-12 col-sm-3 my-1">
                        <InputSearchHotel
                            defaultSelectHotels={hotels}
                            selectedDestinationFromEngine={selectDestination}
                            onOpenPanelHotel={onOpenPanelSearchHotel}
                            closeExternal={showSearchHotel}
                            modeView={modeView}
                            onSelectSearchHotel={onSelectHotel}
                            onUnselectSearchHotel={onUnselectHotel}
                        />
                    </div>
                }

                <div className={!showRay && internalSearchType === 1 ? "col-12 col-sm-4 my-1" : "col-12 col-sm-3 my-1"}>
                    <InputCalendarContainer
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        dateTo={selectDates ? selectDates.dateTo : null}
                        extraOptions={{ multiDate: true }}
                        onClickSelectDates={onClickSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showCalendar}
                        modeView={modeView}
                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                        validDateRule={extraParams ? extraParams.validDateRule : null}
                        type="xl" />
                </div>
                <div className={internalSearchType === 2 ? "col-12 col-sm-2 my-1" : "col-12 col-sm-3 my-1"}>
                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-1 my-1 p-0">
                    <button
                        className="btn tp-btn-search w-100 py-3"
                        onClick={_ => onSearchButton()}
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>
                {
                    canGenerateUrl && <>
                        <div className="col-12 col-sm-11 my-1 p-0">
                            {
                                generateUrl && <div className="mb-3 px-3">
                                    <div className="input-group">
                                        <input type="text" id="inputText" className="form-control h075 border-0 shadow-none w-95 rounded-start" placeholder="Il tuo url" value={generateUrl} />
                                        <button className="btn btn-outline-info w-5 p-0" type="button" onClick={_ => copyText()}>Copia</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-sm-1 my-1 p-0">
                            <button
                                className="btn tp-btn-search w-100 py-3"
                                onClick={_ => onGenerateUrl()}
                                disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                            >
                                {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>Create Url</>}
                            </button>
                        </div>
                    </>
                }

                <div className="col-12 col-sm-9 my-1">
                    <div className="form-check form-check-inline d-none">
                        <input className="form-check-input"
                            type="checkbox"
                            id="FreeBagage"
                            checked={excludeWithPenalty}
                            value="option1"
                            onChange={_ => setExcludeWithPenalty(!excludeWithPenalty)}
                        />
                        <label className={"form-check-label " + textColorClass} htmlFor="inlineCheckbox1">
                            <data m3lab="SearchEngine.ExcludeWithPenalty">
                                {t('SearchEngine:ExcludeWithPenalty')}
                            </data>
                        </label>
                    </div>
                    <div className="form-check form-check-inline d-none">
                        <input className="form-check-input"
                            type="checkbox"
                            id="OnlyRefound"
                            checked={excludePackage}
                            value="option2"
                            onChange={_ => setExcludePackage(!excludePackage)} />
                        <label className={"form-check-label " + textColorClass} htmlFor="inlineCheckbox2">
                            <data m3lab="SearchEngine.ExcludePackage">
                                {t('SearchEngine:ExcludePackage')}
                            </data>
                        </label>
                    </div>
                </div>
                <div className={"col-12 col-sm-3 my-1 d-inline text-right " + textColorClass}>
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship}
                        modeView={modeView} />
                </div>

                {
                    !isValidComposition && <div className={"col-12 col-sm-12 my-1 " + textColorClass}>
                        {t('SearchEngine:WarningCartComposition')}
                    </div>
                }

            </div >
        </>
    );
}