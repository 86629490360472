import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { ListBox } from 'primereact/listbox';
import { scrollTo } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const TemplateSearchEngine = ({ searchParameters, onSetSearchParameters, modeView, template }) => {
    const { t } = useTranslation();

    const [destinationEnabled, setDestinationEnabled] = useState(false);
    const [showDestination, setShowDestination] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showPaxes, setShowPaxes] = useState(false);

    const [selectDestination, setSelectDestination] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);

    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [selectCitizenship, setSelectCitizenship] = useState(null);

    const [tDataFlights, setTDataFlights] = useState(false);
    const [showFlightSelection, setShowFlightSelection] = useState(false);
    const [selectFlight, setSelectFlight] = useState(null);
    const [showSelectFlight, setShowSelectFlight] = useState(null);
    const [selectFlightOptions, setSelectFlightOptions] = useState(null);

    const [isValidForm, setIsValidForm] = useState({ isValidDestination: false, isValidDates: false, isValidComposition: true, isAllFieldValid: false });


    useEffect(() => {
        if (!template.steps || template.steps.length === 0)
            return;

        let templateDatas = [];
        for (let i = 0; i < template.steps.length; i++) {
            for (let j = 0; j < template.steps[i].templateData.length; j++) {
                templateDatas.push(template.steps[i].templateData[j]);
            }
        }

        if (templateDatas.length > 0) {
            // get all Flight TemplateDatas 
            let flights = templateDatas.filter(x => x.productType === configData.Settings.Products.Flight.IdTipoProdotto);
            if (flights.length === 0) {
                // if there aren't flights => hide Destiantion input
                setDestinationEnabled(false);
                setIsValidForm({ isValidDestination: true, isValidDates: false, isValidComposition: true, isAllFieldValid: false });
            } else {
                // if there are flights => check if all flights are DMC or have Pickup and Dropoff
                let flightsNotShowDest = flights.filter(x => x.isDmc === true || (x.pickup && x.dropoff));
                if (flightsNotShowDest.length !== flights.length) {
                    // flights without destination => show Destiantion input
                    setDestinationEnabled(true);
                } else {
                    // flights with destination => hide Destiantion input
                    setDestinationEnabled(false);
                    setIsValidForm({ isValidDestination: true, isValidDates: false, isValidComposition: true, isAllFieldValid: false });

                    // if there are Flights with inclusion "AtLeastrOne" in Step 1, show dropdown to select Flight
                    setTDataFlights(flightsNotShowDest);
                    let flightOptions = [];
                    for (let i = 0; i < flightsNotShowDest.filter(x => x.step === 1).length; i++) {
                        let label = flightsNotShowDest[i].isDmc ? flightsNotShowDest[i].product.itineraryName : `${flightsNotShowDest[i].pickup} > ${flightsNotShowDest[i].dropoff}`
                        flightOptions.push({ name: label, code: flightsNotShowDest[i].templateDataId });
                    }

                    let distictFlightOptions = [];
                    for (let i = 0; i < flightOptions.length; i++) {
                        let item = flightOptions[i];
                        if (distictFlightOptions.findIndex(x => x.name === item.name) < 0)
                            distictFlightOptions.push(item);

                    }

                    setSelectFlightOptions(distictFlightOptions);
                    setShowFlightSelection(true);
                }
            }
        }

        let jSearchParams = localStorage.getItem("TemplateSearchParams");
        if (jSearchParams) {
            localStorage.removeItem("TemplateSearchParams");

            let searchParams = JSON.parse(jSearchParams);

            // fill search fields
            if (searchParams.DepartureCode) {
                setSelectDestination({ departureCode: searchParams.DepartureCode, departureType: searchParams.DepartureType });
            }

            setSelectDates({ dateFrom: searchParams.checkIn });

            let newSelectComposition = [];
            for (let i = 0; i < searchParams.roomsComposition.length; i++){
                newSelectComposition.push({
                    adults: searchParams.roomsComposition[i].adults,
                    children: searchParams.roomsComposition[i].children,
                    childAges: searchParams.roomsComposition[i].childAges
                });
            }
            setSelectComposition(newSelectComposition);

            if (searchParams.SelectedFlightTDataId) {
                setSelectFlight({ code: searchParams.SelectedFlightTDataId, name: searchParams.SelectedFlightName });
            }

            setIsValidForm({ isValidDestination: true, isValidDates: true, isValidComposition: true, isAllFieldValid: true });

            // start event search
            onSetSearchParameters(searchParams);
            scrollTo(null, 'Itinerary');
        }
    }, [template]);


    function checkIsValidForm(destinationUpdate, travelersUpdate, calendarUpdate) {
        let isValidFormUpdate = {
            isValidDestination: isValidForm.isValidDestination,
            isValidDates: isValidForm.isValidDates,
            isValidComposition: isValidForm.isValidComposition,
            isAllFieldValid: false
        };

        if (destinationUpdate) {
            if (destinationUpdate.departureCode)
                isValidFormUpdate.isValidDestination = true;
        } else {
            if (selectDestination && selectDestination.departureCode)
                isValidFormUpdate.isValidDestination = true;
        }

        if (calendarUpdate && calendarUpdate.dateFrom)
            isValidFormUpdate.isValidDates = true;
        else if (selectDates && selectDates.dateFrom)
            isValidFormUpdate.isValidDates = true;

        if (travelersUpdate && travelersUpdate.length > 0)
            isValidFormUpdate.isValidComposition = true;
        else if (selectComposition && selectComposition.length > 0)
            isValidFormUpdate.isValidComposition = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination;

        setIsValidForm(isValidFormUpdate);
    }

    // funzioni per il destination
    function onSelectSearchDestination(item) {
        setShowDestination(false);

        let itemType = 0; // in LocationQualifier: 0 = Airport, 2 = City
        if (item.WebDestinationTypeId !== null && item.WebDestinationTypeId !== undefined && item.WebDestinationTypeId.length > 0) {
            itemType = item.WebDestinationTypeId[0] === 9 ? 0 : 2;
        }

        let dest = { departureCode: item.IataCode, departureType: itemType };
        setSelectDestination(dest);
        checkIsValidForm(dest, null, null);
    }
    function onOpenPanelDestination() {
        setShowDestination(true);
        setShowCalendar(false);
        setShowPaxes(false);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowDestination(false);
        setShowCalendar(true);
        setShowPaxes(false);
        setShowSelectFlight(false);
    }
    function onClickSelectDates(values) {
        setShowCalendar(false);
        setSelectDates({ dateFrom: values.dateFrom, dateTo: values.dateTo, });
        checkIsValidForm(null, null, values);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
        checkIsValidForm(null, selectComposition, null);
    }
    function onOpenPanelPaxes() {
        setShowDestination(false);
        setShowCalendar(false);
        setShowPaxes(true);
        setShowSelectFlight(false);
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }

    // funzioni selezione volo
    function openSelectFlightPanel() {
        setShowSelectFlight(true);
        setShowCalendar(false);
        setShowPaxes(false);
    }
    function onChangeSelectFlight(item) {
        setSelectFlight(item);
        setShowSelectFlight(false);
    }

    // funzioni ricerca
    async function onSearchButton() {
        let totalAdults = 0;
        let totalChildren = 0;
        let totalInfants = 0;

        for (let i = 0; i < selectComposition.length; i++) {
            totalAdults += selectComposition[i].adults;
            totalChildren += selectComposition[i].childAges.filter(x => x > 1).length;
            totalInfants += selectComposition[i].childAges.filter(x => x <= 1).length;
        }

        let newSearchParmas = {
            checkIn: selectDates.dateFrom,
            roomsComposition: selectComposition,
            citizenship: selectCitizenship.isoId,
            totalAdults: totalAdults,
            totalChildren: totalChildren,
            totalInfants: totalInfants
        };

        if (selectDestination) {
            newSearchParmas.DepartureCode = selectDestination.departureCode;
            newSearchParmas.DepartureType = selectDestination.departureType;
        }

        if (selectFlight) {
            newSearchParmas.SelectedFlightTDataId = selectFlight.code;
            newSearchParmas.SelectedFlightName = selectFlight.name;
        }

        if (!searchParameters) {
            onSetSearchParameters(newSearchParmas);
            scrollTo(null, 'Itinerary');
        } else {
            localStorage.setItem("TemplateSearchParams", JSON.stringify(newSearchParmas));
            window.location.reload();
        }
    }


    return (
        <>
            <div className="row mb-3">
                {/*Flight Selection*/}
                {showFlightSelection && !destinationEnabled && tDataFlights &&
                    <div className="col-12 col-sm-3 my-1">
                        <div className="w-100">
                            <div className="input-group rounded pr-1 bg-white" onClick={_ => openSelectFlightPanel()}>
                                <span className="input-group-text px-2 bg-white" id="basic-addon1">
                                    <data m3ico="Flight"><M3Icon iconName="Flight" /></data>
                                </span>
                                <div className="pl-1">
                                    <label htmlFor="ddlFlightSelect" className="form-label h065">{t(`Product:Flight:Item`)}</label>
                                    <input id="ddlFlightSelect" className="form-control h075 w-100 border-0 shadow-none" placeholder={t(`SearchEngine:SelectFlight`)} value={selectFlight && selectFlight.name} readOnly />
                                </div>
                            </div>
                            {showSelectFlight &&
                                <div className="mb-2">
                                    <ListBox value={selectFlight} onChange={(e) => onChangeSelectFlight(e.value)} options={selectFlightOptions} optionLabel="name" listStyle={{ textAlign: 'left' }} />
                                </div>
                            }
                        </div>
                    </div>
                }

                {/*Destination*/}
                {destinationEnabled &&
                    <div className="col-12 col-sm-3 my-1">
                        <InputSearchDestinationContainer
                            defaultSelectDestination={null}
                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                            lastSearchItems={false}
                            onSelectSearchDestination={onSelectSearchDestination}
                            onOpenPanelDestination={onOpenPanelDestination}
                            closeExternal={false}
                            modeView={modeView}
                        />
                    </div>
                }
                {!destinationEnabled && !showFlightSelection && <div className="col-12 col-sm-2 my-1">&nbsp;</div>}

                {/*Calendar*/}
                <div className="col-12 col-sm-3 my-1">
                    <InputCalendarContainer
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        dateTo={selectDates ? selectDates.dateTo : null}
                        onClickSelectDates={onClickSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showCalendar}
                        modeView={modeView}
                        extraOptions={{ multiDate: false, validDates: template.validDates }}
                    />
                </div>

                {/*Pax Composition*/}
                <div className="col-12 col-sm-3 my-1">
                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView} />
                </div>

                {/*Button Search*/}
                <div className="col-12 col-sm-3 my-1 p-0">
                    <button className="btn tp-btn-search w-100 py-3" data-bs-dismiss="modal" onClick={_ => onSearchButton()} disabled={!isValidForm.isAllFieldValid}>
                        {t('Button:Search')}
                    </button>
                </div>

                {/*Citizenship*/}
                <div className="col-12 col-sm-12 my-1 d-inline text-right">
                    <SelectCitizenship defaultCitizenshipId={defaultCitizenshipId} onSelectCitizenship={onSelectCitizenship} />
                </div>
            </div>
        </>
    );
}