import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const TrainSummary = ({ train, isAlternative }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [train]);

    return (
        <>
            <span m3ico="Train mr-2 icon-15">
                <M3Icon iconName="Train" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
            </span>
            [TRAIN]
        </>
    );
}
