import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";
import { imageOnError } from '../../../js/CDNUtility';
import { PassengerDetailPrint } from './PassengerDetailPrint';


export const CarRentalDetailPrint = ({ id, type, showNetPrices, carRentalDetail, isPrintCliente, isConfirmPrint = false, isNotesHide }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {carRentalDetail &&
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0  text-white">NOLEGGIO AUTO</h6>
                    </div>
                    <table>
                        <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                            <td className="borderThead" style={{ width: '18%', maxWidth: '18%' }}>
                                <h6>Immagine</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Descrizione Servizio</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Località Presa</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Località Rilascio</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Data Presa</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Data Rilascio</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Durata</h6>
                                <h6>Giorni</h6>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                            <td className="borderTBody" style={{ width: '18%', maxWidth: '18%' }}>
                                <img src={`data:image;base64, ` + carRentalDetail.thumbUrlB} alt="Thumb" className="card-img" onError={imageOnError} />
                            </td>
                            <td className="borderThead">
                                <h6>{carRentalDetail.name}</h6>
                            </td>
                            <td className="borderThead">
                                <h6>{carRentalDetail.pickUpInfo.address ? carRentalDetail.pickUpInfo.address : carRentalDetail.pickUpInfo.officeName}</h6>
                            </td>
                            <td className="borderThead">
                                <h6>{carRentalDetail.dropOffInfo.address ? carRentalDetail.dropOffInfo.address : carRentalDetail.dropOffInfo.officeName}</h6>
                            </td>
                            <td className="borderThead">
                                <h6>{formatDateTime(carRentalDetail.pickUpInfo.pickupDateTime, cultureName, { withTime: true})}</h6>
                            </td>
                            <td className="borderThead">
                                <h6>{formatDateTime(carRentalDetail.dropOffInfo.dropOffDateTime, cultureName, { withTime: true })}</h6>
                            </td>
                            <td className="borderThead">
                                <h6>{carRentalDetail.option.priceBar.numNights}</h6>
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*note*/}
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: (isPrintCliente ? '1px dotted grey' : 'none'), padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Note aggiuntive</h5>
                                {isNotesHide ?
                                    <span>Vedi in fondo alla pagina</span>
                                    :
                                        carRentalDetail.quotationDetail != null && carRentalDetail.quotationDetail.isManualLoading && carRentalDetail.option.note ?
                                            <div> {/* da lascaire per problema split pagina react PDF */}
                                                <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: carRentalDetail.option.note }} /></p>
                                            </div>
                                        : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    <table>
                        {/*totale costo*/}
                        {!isPrintCliente &&
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {isConfirmPrint &&
                                        <h5>{t(`statiPrenotazione:` + carRentalDetail.option.priceBar.status)}</h5>
                                    }
                                    <label>{carRentalDetail.option.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(carRentalDetail.option.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(carRentalDetail.option.priceBar.invoicePrice, carRentalDetail.option.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        }
                    </table>
                    <table>
                        {/*passeggeri*/}
                        {
                            <PassengerDetailPrint
                                passengers={carRentalDetail.option.passenger}
                            />
                        }
                    </table>
                </div>
            }
        </>
    );
}