import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TourDetailInclusionExclusion = ({ activityDetail }) => {
    const [descriptions, setDescriptions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {

        if (activityDetail.descriptions) {
            let normalizeDescriptions = [];

            for (let i = 0; i < activityDetail.descriptions.length; i++) {
                let d = activityDetail.descriptions[i];
                if (d.type.toUpperCase() === 'INCLUSIONS/EXCLUSIONS' || d.type.toUpperCase() === 'EXCLUSION') {
                    normalizeDescriptions.push(
                        {
                            ...d,
                            items: d.value.split('|')
                        }
                    );
                    setDescriptions(normalizeDescriptions);
                }

                if (d.type.toUpperCase() === 'SAFETYINFO') {
                    normalizeDescriptions.push(
                        {
                            ...d,
                            items: d.value.split('|')
                        }
                    );
                }
            }

        }

    }, [activityDetail]);

    return (
        <>
            {
                descriptions && descriptions.map((desc, key) => {
                    return <div key={key} className="col-12 m-2 card bg-white p-2 shadow">
                        <h5 className="text-green-900">{desc.type.toUpperCase() === 'INCLUSIONS/EXCLUSIONS' && <>{t('Product:Tour:StaticData:Inclusion')}</>}</h5>
                        <h5 className="text-green-900">{desc.type.toUpperCase() === 'SAFETYINFO' && <>{t('Product:Tour:StaticData:SafetyInfo')}</>}</h5>
                        <h5 className="text-danger">{desc.type.toUpperCase() === 'EXCLUSION' && <>{t('Product:Tour:StaticData:Exclusion')}</>}</h5>
                        <ul className="list-group border-0">
                            {
                                desc.items.map((item, key2) => {
                                    return <li key={key2} className=" list-group-item">
                                        <label className="lh-sm" dangerouslySetInnerHTML={{ __html: item }} />
                                    </li>;
                                })
                            }
                        </ul>
                    </div>;
                })
            }
        </>
    );
}