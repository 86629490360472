import React from 'react';
import { formatDateTime } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom } from '../../../js/PrintUtility';
import { IconChoosePrint } from './../../Print/components/IconChoosePrint';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";


export const QuotationPrintStepHeader = (props) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    
    return (
        <table>
            <tr className="badge-customer" style={{ textAlign: 'left' }}>
                {props.iconName &&
                    <td style={{ width: '10%', maxWidth: '10%', marginTop: '-10px', marginBottom: '-15px', }}>
                        <img src={getIconUrlToPrintCustom(props.iconName)} className="ico-image-extra-large" />
                    </td>
                }
                <td className="text-white" style={{ textAlign: 'left', margin: '5px', marginLeft: '20px', }}>
                    {props.stepIndex && 
                        <h5 className="text-white">
                            Step {(configData.Settings.QuotationTO.AutoGenerateStepGroupBy === "1" ? (props.stepIndex - 1) : props.stepIndex)} &nbsp; {props.destination && <> - {props.destination}</>}
                        </h5>
                    }
                    {props.title &&
                        <h4 className="text-white">
                            { props.title }
                        </h4>
                    }
                </td>
                {props.PNR &&
                    <td className="col-lg-3 text-white" style={{ textAlign: 'left', borderLeft: '1px solid #FFFFFF', }}>
                        <tr>
                            <div className="badge-white" style={{ color: 'black', padding: '2px', left: '23px', top: '7px' }}>
                                <h6>PNR: {props.PNR}</h6>
                            </div>
                        </tr>
                    </td>
                }
                {props.totaleViaggio &&
                    <td className="col-lg-3 text-white" style={{ textAlign: 'left', borderLeft: '1px solid #FFFFFF', }}>
                        <tr>
                            <div className="badge-white" style={{ color: 'black', padding: '2px', left: '23px', top: '5px' }}>
                                <h5>{props.totaleViaggio}</h5>
                            </div>
                        </tr>
                    </td>
                }
                {props.minDate && props.maxDate &&
                    <td className="col-lg-3 text-white" style={{ textAlign: 'right', margin: '5px',}}>
                        <h5 className="text-white">
                            {t(`Template:StartStep`)} {formatDateTime(props.minDate, cultureName, { noYear: true })}
                            &nbsp;
                            {t(`Template:EndStep`)} {formatDateTime(props.maxDate, cultureName, { noYear: true })}
                        </h5>
                    </td>
                }
            </tr>
        </table>
    );
}