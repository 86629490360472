import React from 'react';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { useTranslation } from 'react-i18next';


export const QuotationPrintCancelPolicy = ({ cancelPolicyArr, cultureName, onHandleIsPrint }) => {
    const { t } = useTranslation();

    function correctDate(date) {
        let today = new Date();
        let date_ = new Date(date);

        if (date_ < today) {
            return today.toISOString();
        }

        return date;
    }

    return (
        <>
            {cancelPolicyArr && cancelPolicyArr.length > 0 &&
            <>
                <div className="border shadow">
                    <table className="w-100">
                        <thead>
                            <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                                <td className="borderThead">
                                    <h6>Servizio</h6>
                                </td>
                                <td className="borderThead" style={{ width: '30%', maxWidth: '30%', }}>
                                    <h6>Data</h6>
                                </td>
                                <td className="borderThead" style={{ width: '20%', maxWidth: '20%', }}>
                                    <h6>Percentuale</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {cancelPolicyArr.map((policy, index) =>
                                policy.cancelPolicy.map((cancPolicy, idx) =>
                                    <tr key={index} style={{ borderBottom: (idx === policy.cancelPolicy.length-1) ? '1px solid black': "", borderRight: '1px dotted grey' }}>
                                        <td className="borderTBody" style={{ textAlign: 'left' }}>
                                            <h6>{idx === 0 ? policy.name : ""}</h6>
                                        </td>
                                        <td className="borderTBody" style={{ textAlign: 'left', width: '30%', maxWidth: '30%', }}>
                                            <h6>{formatDateTime(correctDate(cancPolicy.date), cultureName)}</h6>
                                        </td>
                                        <td className="borderTBody" style={{ textAlign: 'right', width: '20%', maxWidth: '20%', }}>
                                            <h6>{cancPolicy.percent}%</h6>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </>
            }
        </>
    );
}