import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { printCssStyle } from '../../../Custom/Print/PrintCss';
import { generateAccountBalanceHTML } from "./AccountBalanceService";
import { downloadDocumentPDF } from "../../../../js/PrintUtility";
import { PrintPdfAccountBalance } from './components/PrintPdfAccountBalance';

export const AccountBalancePrintButton = ({ headerData, titleData, order, visualizationType, userType, grouped, cultureName, scadenziario }) => {
    const [myHtml, setMyHtml] = useState([]);
    const [pdfFileName, setPdfFileName] = useState('');
    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [isDownloadPDF, setIsDownloadPDF] = useState(false);

    function onClickPrintPDF() {
        setIsDownloadPDF(true);
        callPrintPDF();
    }

    function callPrintPDF() {
        const today = new Date().toISOString().split('T')[0];
        setPdfFileName('AccountBalance_' + today);

        let myArr = generateAccountBalanceHTML(
            titleData,
            order,
            visualizationType,
            userType,
            grouped,
            scadenziario,
            cultureName
        );

        setMyHtml(myArr);
        setExtraInfoPrint({
            headerData: headerData
        });
    }

    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);
        setIsDownloadPDF(false);
    }

    return (
        <>
            <a id="printAccountBalance" className="btn btn-md badge bg-primary text-white ml-2" onClick={onClickPrintPDF} ></a>
            {
                isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfAccountBalance html={myHtml} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                    {({ blob, url, loading, error }) => {
                        if (!loading && blob) {
                            dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                        }
                    }}
                </PDFDownloadLink>
            }
        </>
    );
}