import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer.js';
import { createStepsWithNewOne, createStepsMissing, createStepsRemoveOne, changeStepNights, createStepSearchParam, isPreviousVoidStep, changeStepNightsFromSearchParam, createStepsSearchParam, canAddAnotherStep } from '../service/TrippieService';
import { formatDateTime, addDaysToDatePicker, compareDates } from '../../../js/Utils.js';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer.js';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer.js';

export const TrippieManageStepModal = ({ steps, searchParameters, onSaveSteps }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    // Steps
    const [updSteps, setUpdSteps] = useState(null);
    const [showChangeNameIndex, setShowChangeNameIndex] = useState(null);
    const [newStepNameValue, setNewStepNameValue] = useState('');

    const [showChangeDestinationIndex, setShowChangeDestinationIndex] = useState(null);
    const [stepDestinationOpen, setStepDestinationOpen] = useState(false);
    const [selectStepDestination, setSelectStepDestination] = useState({});

    // Base Info
    const [selectComposition, setSelectComposition] = useState([{
        adults: 2,
        children: 0,
        childAges: []
    }]);

    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    const [selectDates, setSelectDates] = useState(null);
    const [isValidDates, setIsValidDates] = useState(true);

    useEffect(() => {

        let modalRichiestaIntereventoCommerciale = document.getElementById('modal_trippie_manage_step');
        modalRichiestaIntereventoCommerciale.addEventListener('show.bs.modal', function () {
            resetModal();
        });

        resetModal();
        setIsValidDates(true);
    }, [steps, searchParameters])

    function resetModal() {
        if (steps && steps.length > 0)
            setUpdSteps([...steps]);
        else
            setUpdSteps(null);

        setShowChangeNameIndex(null);
        setNewStepNameValue(null);

        if (searchParameters) {

            setSelectDates({
                dateFrom: searchParameters.dateFrom,
                dateTo: searchParameters.dateTo
            });

            if (searchParameters.roomsComposition && searchParameters.roomsComposition.length > 0)
                setSelectComposition(searchParameters.roomsComposition);
        }
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setShowPaxes(false);

        setSelectComposition(selectComposition);
    }

    function onOpenPanelPaxes() {
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowCalendar(true);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        let updSteps = [...steps];
        changeStepNightsFromSearchParam(values.dateFrom, updSteps, values.dateTo);
        createStepsSearchParam(updSteps, searchParameters.roomsComposition);

        let isValid = true;
        for (let i = 0; i < updSteps.length; i++) {
            let stepTo = updSteps[i].stepTo;

            if (compareDates(stepTo, values.dateTo) === 1)
                isValid = false;

            if (!isValid)
                break;
        }

        if (isValid)
            setUpdSteps(updSteps);

        setIsValidDates(isValid);
    }


    // Manage Steps

    function onShowUpdateStepName(stepIndex) {
        if (stepIndex === -1) {
            let updStepWithValueUpd = [...updSteps];

            if (showChangeNameIndex) {
                updStepWithValueUpd[showChangeNameIndex].stepName = newStepNameValue;
                setUpdSteps(updStepWithValueUpd);
            }

            setShowChangeNameIndex(null);
            setNewStepNameValue(null);

            return updStepWithValueUpd;
        }

        if (stepIndex !== showChangeNameIndex) {
            setShowChangeNameIndex(stepIndex);
            setNewStepNameValue(updSteps[stepIndex].stepName);
        } else {

            let updStepWithValueUpd = [...updSteps];
            updStepWithValueUpd[showChangeNameIndex].stepName = newStepNameValue;
            setUpdSteps(updStepWithValueUpd);

            setShowChangeNameIndex(null);
            setNewStepNameValue(null);

            return updStepWithValueUpd;
        }
    }

    function onChangeStepName(event) {
        let newValue = event.target.value;
        setNewStepNameValue(newValue);
    }

    function onClickChangeNights(stepIndex, newNightsValue) {
        let updStepWithValueUpd = [...updSteps];

        let nights = parseInt(newNightsValue);
        if (!nights)
            nights = 0;

        // this mean I'm adding nights
        let isAddingNights = nights - updStepWithValueUpd[stepIndex].nights;
        debugger;
        updStepWithValueUpd[stepIndex].nights = nights;
        updStepWithValueUpd[stepIndex].stepTo = addDaysToDatePicker(updSteps[stepIndex].stepFrom, nights);

        updStepWithValueUpd = changeStepNights(updStepWithValueUpd, stepIndex, searchParameters.dateTo, isAddingNights);
        updStepWithValueUpd = createStepSearchParam(updStepWithValueUpd, searchParameters.roomsComposition);

        setUpdSteps(updStepWithValueUpd);

        let isValid = true;
        for (let i = 0; i < updStepWithValueUpd.length; i++) {
            let stepTo = updStepWithValueUpd[i].stepTo;

            if (compareDates(stepTo, selectDates.dateTo) === 1)
                isValid = false;

            if (!isValid)
                break;
        }

        setIsValidDates(isValid);
    }

    function onShowUpdateDestination(stepIndex) {
        if (stepIndex !== showChangeNameIndex) {
            setShowChangeDestinationIndex(stepIndex);
            setStepDestinationOpen(true);

            let stepInfo = updSteps[stepIndex];
            if (stepInfo.webDestinationId)
                setSelectStepDestination({
                    WebId: stepInfo.webDestinationId,
                    Text: stepInfo.webDestinationName,
                    Type: stepInfo.webDestinationType
                });
            else
                setSelectStepDestination({});

        } else {
            setShowChangeDestinationIndex(null);
            setSelectStepDestination({});
            setStepDestinationOpen(false);
        }
    }

    function onOpenPanelDestination() {
        setStepDestinationOpen(!stepDestinationOpen);
        setShowChangeDestinationIndex(null);
        setSelectStepDestination({});
    }

    function onClosePanelDestination() {
        setStepDestinationOpen(false);
        setShowChangeDestinationIndex(null);
        setSelectStepDestination({});
    }

    function onSelectSearchDestination(item) {
        let updStepWithValueUpd = [...updSteps];
        updStepWithValueUpd[showChangeDestinationIndex].webDestinationId = item.Id;
        updStepWithValueUpd[showChangeDestinationIndex].webDestinationName = item.Text;

        if (!updStepWithValueUpd[showChangeDestinationIndex].stepName)
            updStepWithValueUpd[showChangeDestinationIndex].stepName = item.Text;

        setUpdSteps(updStepWithValueUpd);

        setStepDestinationOpen(!stepDestinationOpen);
        setShowChangeDestinationIndex(null);
        setSelectStepDestination({});
    }

    function onAddStep(index, stepType = null) {
        let updStepsCopy = [];
        if (updSteps && updSteps.length > 0)
            updStepsCopy = [...updSteps];
        else
            updStepsCopy = [];

        let newSteps = createStepsWithNewOne(index, updStepsCopy, searchParameters, stepType);
        setUpdSteps(newSteps);
    }

    function onAddStepFlight(index) {
        onAddStep(index, (configData.Settings.Products.Flight.IdTipoProdotto + ""))
    }

    function onAddMissingStep(index) {
        let updStepsCopy = [...updSteps];
        let newSteps = createStepsMissing(index, updStepsCopy, searchParameters);
        setUpdSteps(newSteps);
    }

    function onRemoveStep(index) {
        let updStepsCopy = [...updSteps];
        let newSteps = createStepsRemoveOne(index, updStepsCopy);
        setUpdSteps(newSteps);
    }

    function onConfirmSaveSteps() {
        let stepsUpdateNames = onShowUpdateStepName(-1);

        let stepsToSave = updSteps;
        if (stepsUpdateNames && stepsUpdateNames.length > 0)
            stepsToSave = stepsUpdateNames;

        let baseInfo = {
            Text: searchParameters.Text,
            dateFrom: selectDates.dateFrom,
            dateTo: selectDates.dateTo,
            roomsComposition: selectComposition
        };

        onSaveSteps(baseInfo, updSteps);
    }

    function getYesterdayDate() {

        const today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        return new Date(yesterday);
    }

    return (
        <>
            <a id="open_modal_trippie_manage_step" data-bs-toggle="modal" data-bs-target="#modal_trippie_manage_step"></a>
            <div className="modal fade" id="modal_trippie_manage_step" tabIndex="-1" aria-labelledby="modal_trippie_manage_step_label" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header bg-gray-400">
                            <h5 className="modal-title" id="modal_trippie_manage_step_label">Gestisci i tuoi step</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row bg-custom py-3">
                                <div className="col-12 col-sm-12 col-md-6">
                                    <InputCalendarContainer
                                        minDate={getYesterdayDate()}
                                        isForceOpenLeft={true}
                                        dateFrom={selectDates ? selectDates.dateFrom : null}
                                        dateTo={selectDates ? selectDates.dateTo : null}
                                        extraOptions={{ multiDate: true }}
                                        onOpenPanelCalendar={onOpenPanelCalendar}
                                        onClickSelectDates={onClickSelectDates}
                                        modeView="MODAL"
                                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                        closeExternal={showCalendar}
                                        type="xl" />
                                </div>
                                <div className="col-12 col-sm-12 col-md-6">
                                    <InputPaxesCompositionContainer
                                        searchParameters={searchParameters}
                                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                        callOnSelectComposition={onClickSelectPaxes}
                                        onOpenPanelPaxes={onOpenPanelPaxes}
                                        closeExternal={showPaxes}
                                        modeView="MODAL"
                                        type="xl" />
                                </div>
                                {
                                    !isValidDates && <div className="col-12 bg-danger text-white text-center mt-2">
                                        La somma delle notti dell'itinerario negli step è maggiore del periodo selezionato
                                    </div>
                                }
                            </div>
                            {
                                ((searchParameters && searchParameters.dateFrom) && (!updSteps || updSteps.length == 0)) && <>
                                    <div className="row bg-light shadow-sm py-2">
                                        <div className="col-12 text-right">
                                            <button className="h075 btn btn-outline-success shadow mr-2" onClick={_ => onAddStepFlight(1)} style={{ "borderRadius": "100px" }} >
                                                <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Volo
                                            </button>

                                            <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddStep(1)} style={{ "borderRadius": "100px" }} >
                                                <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                updSteps && updSteps.map((stepInfo, index) => {
                                    return <>
                                        {
                                            index === 0 && isPreviousVoidStep(stepInfo, selectDates.dateFrom) && <div className="row bg-light shadow-sm py-2">
                                                <div className="col-8 text-danger">
                                                    Soggiorno Libero dal {formatDateTime(selectDates.dateFrom, cultureName, { monthLabel: true, noYear: true })} al {formatDateTime(stepInfo.stepFrom, cultureName, { monthLabel: true, noYear: true })}
                                                </div>
                                                <div className="col-4 text-right">
                                                    <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddMissingStep(-1)} style={{ "borderRadius": "100px" }} >
                                                        <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        <div className={"row mb-1 shadow-sm pt-2 pb-3 " + (stepInfo.isOutsideRange ? "border border-danger" : "")}>
                                            <div className="col-2">
                                                <div className="badge bg-white text-dark p-0">
                                                    <div>
                                                        {
                                                            stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? <M3Icon iconName="Flight" />
                                                                : <M3Icon iconName="Destination" />
                                                        }
                                                        <span className="ml-1 position-relative" style={{ 'top': '-5px' }}>
                                                            <small>{formatDateTime(stepInfo.stepFrom, cultureName, { monthLabel: false, noYear: true })}</small>
                                                            {
                                                                stepInfo.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "") && stepInfo.stepTo && <small><span className="mx-1">-</span> {formatDateTime(stepInfo.stepTo, cultureName, { monthLabel: false, noYear: true })}</small>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="ml-4">
                                                    {

                                                        stepInfo.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "") && <select className="rounded py-1 px-1 w-auto h075" onChange={(e) => onClickChangeNights(index, e.currentTarget.value)}>
                                                            ? {
                                                                [...Array((stepInfo.maxNights + 1))]
                                                                    .map((_, i) => i)
                                                                    .map(i => <option key={i} value={i} selected={(stepInfo.nights + "") === (i + "")}>{i} Notti</option>)
                                                            }
                                                            : <></>
                                                        </select>
                                                    }
                                                </div>

                                            </div>
                                            <div className="col-4">
                                                <div className="fw-bold mt-2">

                                                    <button
                                                        className="btn btn-small btn-outline-primary rounded-circle shadow px-1 py-1 mr-2 position-relative"
                                                        onClick={_ => onShowUpdateStepName(index)}
                                                        style={{ 'top': '-2px' }}>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <M3Icon iconName="Edit" externalClass="h075" />
                                                        </div>
                                                    </button>

                                                    {
                                                        showChangeNameIndex === index ? <input
                                                            id="trippie_travelname"
                                                            type="text"
                                                            className="w-70 small"
                                                            value={newStepNameValue}
                                                            placeholder={t('MyBook:Name')}
                                                            onChange={e => onChangeStepName(e)} />
                                                            : <>
                                                                {
                                                                    stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")
                                                                        ? <>{stepInfo.stepName ? stepInfo.stepName : <>Volo</>}</>
                                                                        : <>{stepInfo.stepName ? stepInfo.stepName : <>Step {index + 1}</>}</>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-5 text-left">
                                                <div className="mt-2">
                                                    {
                                                        showChangeDestinationIndex === index ? <>
                                                            <InputSearchDestinationContainer
                                                                defaultSelectDestination={selectStepDestination}
                                                                productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                                                onSelectSearchDestination={onSelectSearchDestination}
                                                                onOpenPanelDestination={onOpenPanelDestination}
                                                                closeExternal={stepDestinationOpen}
                                                                clearSearch={stepDestinationOpen}
                                                                hideAllInput={onClosePanelDestination}
                                                                extraOptions={{ rules: { enableDestination: false, enableCity: true } }}
                                                                modeView="MODAL" />
                                                        </>
                                                            : <>
                                                                {
                                                                    stepInfo.webDestinationName ? stepInfo.webDestinationName
                                                                        : <span>{t('City')}</span>
                                                                }
                                                                {
                                                                    stepInfo.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "") && <button className="btn btn-small btn-outline-primary rounded-circle shadow ml-1 px-1 py-1" onClick={_ => onShowUpdateDestination(index)}>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {
                                                                                stepInfo.webDestinationName ? <M3Icon iconName="Edit" externalClass="h075" />
                                                                                    : <M3Icon iconName="Add" externalClass="h075" />
                                                                            }
                                                                        </div>
                                                                    </button>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-1 text-align-right">
                                                <button className="btn btn-small btn-outline-danger rounded-circle shadow float-end mt-2 px-2 py-2" onClick={_ => onRemoveStep(index)} >
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <M3Icon iconName="Delete" externalClass="h095" />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row bg-light shadow-sm py-2">
                                            {
                                                stepInfo.missingDateToNextStep && <>
                                                    <div className="col-8 text-danger">
                                                        Soggiorno Libero dal {formatDateTime(stepInfo.missingStepFrom, cultureName, { monthLabel: true, noYear: true })} al {formatDateTime(stepInfo.missingStepTo, cultureName, { monthLabel: true, noYear: true })}
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddMissingStep(index)} style={{ "borderRadius": "100px" }} >
                                                            <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            {
                                                !stepInfo.missingDateToNextStep && canAddAnotherStep(updSteps, index, selectDates.dateTo) && <>

                                                    <div className="col-12 text-right">
                                                        <button className="h075 btn btn-outline-success shadow mr-2" onClick={_ => onAddStepFlight(index)} style={{ "borderRadius": "100px" }} >
                                                            <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Volo
                                                        </button>

                                                        <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddStep(index)} style={{ "borderRadius": "100px" }} >
                                                            <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                })
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("Button:Close")}</button>
                            <button type="button" disabled={!isValidDates} className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={_ => onConfirmSaveSteps()}>
                                {t("Button:Save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
