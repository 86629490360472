import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { PassengerPrint } from './PassengerPrint';
import { PriceBarPrint } from './PriceBarPrint';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { imageOnError } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements } from '../../../js/PrintUtility';
import { PassengerDetailPrint } from './PassengerDetailPrint';

export const TourDetailPrint = ({ id, type, showNetPrices, tourDetail, isPrintCliente, productSubType, isNotesHide, isConfirmPrint = false }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    function getFinalRooms(rooms) {
        if (isConfirmPrint) {
            return rooms.filter(x =>
                x.priceBar.status === "CONFIRMED" ||
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
        }
        else { // quotation
            return rooms;
        }
    }


    function getTouchPoint(touchPoints) {
        let res = "";
        touchPoints.forEach(function (touchPoint) {
            res = res + " " + touchPoint.webDestinationName + ",";
        });
        
        res = res.substring(0, res.length - 1);

        return res;
    }

    return (
        <>
            {tourDetail ?
                <div style={{ display: 'grid' }}>
                    <div className="text-white badge-customer" style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' }}>
                        <h6 className="mb-0 text-white">TOUR</h6>
                    </div>
                    {tourDetail.rooms && tourDetail.rooms.length > 0 && getFinalRooms(tourDetail.rooms).map((room, key) =>
                        <>
                            <table>
                                {key === 0 && /* per versione html, fa si che header tabella sia allineato con tbody */
                                    <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                                        <td className="borderThead" style={{ width: '18%', maxWidth: '18%' }}>
                                            <h6>Immagine</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Nome Struttura</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Indirizzo</h6>
                                            <h6>Struttura</h6>
                                        </td>
                                        <td className="borderThead" style={{ width: '3%', maxWidth: '3%' }}>
                                            <img src={getIconUrlToPrint('person')} className="ico-image" />
                                        </td>
                                        <td className="borderThead">
                                            <h6>Tipo Camera</h6>
                                        </td>
                                        <td className="borderThead">
                                            <h6>Tipo</h6>
                                            <h6>Trattamento</h6>
                                        </td>
                                        <td className="borderThead" style={{ width: '12%', maxWidth: '12%' }}>
                                            <h6>Check IN</h6>
                                        </td>
                                        <td className="borderThead" style={{ width: '12%', maxWidth: '12%' }}>
                                            <h6>Check OUT</h6>
                                        </td>
                                        <td className="borderThead" style={{ width: '6%', maxWidth: '6%', textAlign: 'center' }}>
                                            <h6>N.</h6>
                                            <h6>Notti</h6>
                                        </td>
                                    </tr>
                                }
                                <tr key={key} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                    <td className="borderTBody" style={{ width: '18%', maxWidth: '18%' }}>
                                        <img src={`data:image;base64, ` + tourDetail.thumbUrlB} alt="Thumb" className="card-img" onError={imageOnError} />
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{tourDetail.name}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{tourDetail.address}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '3%', maxWidth: '3%' }}>
                                        <h6>{room.adults + room.children}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{room.roomName}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{room.planName}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '12%', maxWidth: '12%' }}>
                                        <h6>{formatDateTime(tourDetail.priceBar.startDate, cultureName)}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '12%', maxWidth: '12%' }}>
                                        <h6>{formatDateTime(tourDetail.priceBar.endDate, cultureName)}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '6%', maxWidth: '6%' }}>
                                        <h6>{tourDetail.priceBar.numNights}</h6>
                                    </td>
                                </tr>
                            </table>
                            {/*note*/}
                            <table>
                                <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: '1px dotted grey', padding: '5px' }}>
                                    <td style={{ textAlign: 'left' }}>
                                        {room.resortFees.length > 0 && <>
                                            <h5>Resort Fee</h5>
                                            <div>
                                                {room.resortFees.map((fee, index) =>
                                                    <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(fee) }} /></p>
                                                )}
                                            </div>
                                            <br />
                                        </>
                                        }
                                        <h5>Note aggiuntive</h5>
                                        {isNotesHide ?
                                            <span>Vedi in fondo alla pagina</span>
                                            :
                                            room.notes.length > 0 &&
                                                <div> {/* da lascaire per problema split pagina react PDF */}
                                                    {room.notes && room.notes.map((note, index) =>
                                                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} /></p>
                                                    )}
                                                </div>
                                        }
                                        {tourDetail.touchPoints && tourDetail.touchPoints.length > 0 &&
                                        <>
                                            <h5>Touch Point</h5>
                                            <div className="text-left" style={{ marginTop: "4px" }}>
                                                <span style={{ textAlign: 'left', marginLeft: "5px"}}>{getTouchPoint(tourDetail.touchPoints)}</span>
                                            </div>
                                        </>
                                        }
                                    </td>
                                </tr>
                            </table>
                            <table>
                                {/*passeggeri*/}
                                {
                                    <PassengerDetailPrint
                                        passengers={room.passengers}
                                    />
                                }
                            </table>
                        </>
                    )}
                    {/*totale costo*/}
                    {!isPrintCliente &&
                        <table>
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {
                                        tourDetail.rooms && tourDetail.rooms.length > 0 && getFinalRooms(tourDetail.rooms).map((room, key) => <>
                                            {isConfirmPrint ? <>
                                                <h5>Camera {key + 1}: {t(`statiPrenotazione:` + room.priceBar.status)}</h5>
                                                <label>{room.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(room.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                            </>
                                                :
                                                <label>Camera {key + 1}: {room.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(room.cancelPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}</label>
                                            }
                                        </>)
                                    }
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(tourDetail.priceBar.invoicePrice, tourDetail.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        </table>
                    }
                </div>
                :
                <></>
            }
        </>
    );
}